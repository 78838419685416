.scan-to-print-status-indicator {
  width: 400px;
  margin: 250px auto 0;
  position: relative;
  z-index: 180001;
}
.scan-to-print-status-indicator.zero {
  width: 550px;
  padding: 50px;
  font-size: 36px;
  text-align: center;
  background: #3C464B;
  color: #ffffff;
  border-radius: 4px;
  z-index: 100;
  font-weight: 700;
}
.scan-to-print-status-indicator.warning {
  width: 550px;
  background: #FFF4E9;
  font-size: 25px;
  color: #262626;
  border-radius: 4px;
  z-index: 100;
}
.warning-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.warning-icon {
  height: 50px;
  width: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.warning-icon > svg {
  height: 30px;
  width: 30px;
  fill: #FF8000;
}
.warning-message {
  line-height: 50px;
  font-size: 26px;
  width: 80%;
}
.regular-status {
  background: #ffffff;
  color: #000000;
  border-radius: 0 0 4px 4px;
}
.progress-bar-wrapper {
  width: 100%;
  height: 5px;
  background: #E6F0F5;
}
.regular-status-message {
  padding: 20px;
  font-size: 30px;
  line-height: 35px;
}
.error-message {
  font-size: 14px;
  line-height: 14px;
  margin-top: 10px;
}
