.dropdown-menu-item {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.1;
  display: block;
  width: 100%;
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #E0E0E0;
  font-size: 13px;
  color: #262626;
  background: #FFF;
  cursor: pointer;
}
.dropdown-menu-item:hover {
  background: #EEF8FD;
  text-decoration: none;
}
.dropdown-menu-item.remove-padding {
  padding: 0;
}
.dropdown-menu-item:last-child {
  border-bottom: 0;
}
.dropdown-menu-item.disabled {
  cursor: default;
  color: #909090;
  opacity: 0.4;
}
.dropdown-menu-item.disabled:hover {
  background: #FFF;
}
.dropdown-menu-item.selected {
  background: #EEF8FD;
}
.dropdown-menu-item.selected:hover {
  background: #EEF8FD;
}
