.message {
  padding: 20px;
}
.message p {
  font-size: 15px;
  color: #4C4C4C;
}
.container {
  height: 85px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px 15px;
}
.container > div:first-child {
  border-color: #E0E0E0;
  background: #ffffff;
}
.container > div:first-child > svg {
  fill: #E0E0E0;
}
.container > div:first-child h3 {
  color: #909090;
}
.container > div.arrow-right {
  width: 40px;
  height: 15px;
  position: relative;
  display: inline-block;
  margin: 20px;
}
.container > div.arrow-right:before,
.container > div.arrow-right:after {
  content: "";
  border-color: transparent;
  border-style: solid;
  position: absolute;
}
.container > div.arrow-right:before {
  border: none;
  background-color: #4380e8;
  height: 4px;
  width: 30px;
  top: 4px;
  left: 0;
}
.container > div.arrow-right:after {
  left: 30px;
  top: 0;
  border-width: 6px 10px;
  border-left-color: #4380e8;
}
.footer {
  border-top: 2px solid #F6F6F6;
  padding: 20px 20px 10px;
}
.billing-error {
  width: 550px;
  margin: 10px auto 15px;
  list-style: none;
  color: #FF4016;
}
