.modal-content {
  padding: 0 5px;
  overflow: auto;
}
:global div.react-generic-modal,
:global div.react-generic-modal > div {
  height: 100%;
}
.billing-description {
  font-size: 16px;
  border-bottom: 1px solid #E0E0E0;
  padding-bottom: 10px;
}
