.unauthorized-container {
  text-align: center;
  vertical-align: middle;
  padding-top: 5%;
  font-size: 4em;
  color: gray;
  background: #E6F0F5;
  height: 100vh;
}
.unauthorized-message {
  font-size: 26px;
  padding-left: 5%;
  padding-right: 5%;
}
