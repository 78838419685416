.checkbox-slider {
  display: inline-block;
  font-size: 0;
  vertical-align: top;
}
.checkbox-slider input[type="checkbox"] {
  opacity: 0;
  display: none;
}
.checkbox-slider input[type="checkbox"]:checked + label {
  color: #262626;
}
.checkbox-slider input[type="checkbox"]:checked + label:after {
  margin-left: 15px;
  background: #8d60b5;
}
.checkbox-slider input[type="checkbox"]:checked + label:before {
  background: rgba(141, 96, 181, 0.5);
}
.checkbox-slider input[type="checkbox"]:disabled + label {
  color: #4C4C4C;
  opacity: 0.75;
  cursor: default;
}
.checkbox-slider input[type="checkbox"]:disabled + label:after {
  margin-left: 15px;
  background: #C3C3C3;
}
.checkbox-slider input[type="checkbox"]:disabled + label:before {
  background: #E0E0E0;
}
.checkbox-slider label {
  cursor: pointer;
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-size: 13px;
  color: #909090;
  -webkit-transition: all ease 200ms;
  transition: all ease 200ms;
}
.checkbox-slider label:before,
.checkbox-slider label:after {
  content: "";
  display: inline-block;
  vertical-align: top;
  -webkit-transition: all ease 200ms;
  transition: all ease 200ms;
}
.checkbox-slider label:before {
  height: 14px;
  width: 28px;
  background: #FFF;
  border: 1px solid #C3C3C3;
  border-radius: 7.5px;
  margin-right: 7.5px;
}
.checkbox-slider label:after {
  position: absolute;
  width: 13px;
  height: 13px;
  left: 1px;
  top: 1px;
  background: #C3C3C3;
  border-bottom: 1px solid rgba(38, 38, 38, 0.3);
  border-radius: 7.5px;
  text-align: center;
}
