.header-row-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.round-button {
  border-radius: 14px;
  color: #ffffff;
  background-color: #502278;
}
.round-button:hover {
  background-color: #623886;
}
.round-button:active,
.round-button.selected {
  background-color: #4c2072;
}
.description {
  padding: 15px 0;
}
.create-new-theme {
  padding: 15px 0;
}
.theme-list {
  clear: both;
  width: 100%;
  margin-top: 10px;
  padding: 5px 0;
  background-color: #ffffff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: #E0E0E0 1px solid;
}
.theme-list tr > th {
  padding: 5px;
  background-color: #EEEEEE;
}
.theme-list tr > td {
  padding: 5px;
  border-bottom: #E0E0E0 1px solid;
}
.theme-list .base-url {
  max-width: 300px;
}
.theme-list td.base-url {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-family: monospace;
  font-size: 10px;
}
.theme-list .base-url-copy-text {
  float: left;
  display: inline-block;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.theme-list .copy {
  width: 20px;
  padding-right: 30px;
}
.theme-list .copy-icon {
  cursor: pointer;
  background-color: transparent;
}
.theme-list .copy-icon:active {
  background-color: transparent;
}
.theme-list .copy-icon:hover {
  text-decoration: none;
  background-color: transparent;
}
.theme-list .copied {
  color: #7DCA36;
  background-color: transparent;
}
