.setup-shipengine-carrier-services-modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: justify;
      align-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: space-evenly;
      align-content: space-evenly;
}
.row-item {
  margin-bottom: 10px;
  margin-right: 10px;
}
.row-item:last-child {
  margin-right: 0;
}
.first-name {
  width: calc(50% - 10px);
}
.last-name {
  width: 50%;
}
.email {
  width: calc(50% - 10px);
}
.phone {
  width: 50%;
}
.form-wrapper {
  width: 550px;
  margin-left: auto;
  margin-right: auto;
}
.form-wrapper h3 {
  font-size: 16px;
  font-weight: 700;
  padding: 10px 0;
  border-bottom: 1px solid #F6F6F6;
  margin-bottom: 12px;
}
.billing-error {
  color: #FF4016;
}
.loading {
  height: 50px;
}
.logos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.logos .logo-wrapper {
  display: grid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  justify-items: center;
  height: 125px;
  width: 175px;
}
.logos .logo-wrapper .logo {
  max-width: 125px;
  max-height: 60px;
}
.footer {
  margin-top: auto;
  margin-bottom: 5px;
}
.footer .footer-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.footer .footer-buttons .back-button {
  margin-right: 15px;
}
