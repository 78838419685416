/* ShipStation Base Styles */
*,
*:before,
*:after {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
a {
  color: #4791CE;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
a:focus {
  outline: none;
  background: #EEF8FD;
}
ul {
  display: inline-block;
  border-bottom: none;
  margin: 0;
  padding: 0;
  /* stylelint-disable */
  list-style: none;
  list-style-type: none;
  list-style-position: none;
  -moz-list-style: none none none;
  -moz-margin-before: 0;
  -moz-margin-after: 0;
  -moz-margin-start: 0;
  -moz-margin-end: 0;
  -moz-padding-start: 0;
  -moz-padding-end: 0;
  -webkit-list-style: none none none;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
  -webkit-padding-end: 0;
  /* stylelint-enable */
}
/* Disabled for V2 */
/* ul li {
  display: inline-block;
} */
html {
  line-height: 1;
  -webkit-text-size-adjust: 100%;
  font-size: 16px;
  color: #4C4C4C;
}
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background: #FFF;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border-top: 1px solid #E0E0E0;
}
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
abbr[title] {
  border-bottom: none;
  /* stylelint-disable */
  text-decoration: underline;
  text-decoration: underline dotted;
  /* stylelint-enable */
}
b,
strong {
  font-weight: 700;
}
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1;
  margin: 0;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  padding: 0;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
details {
  display: block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
* {
  font-family: "Roboto", Arial Narrow, Geneva, Lucida Grande, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
}
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
input {
  height: 30px;
  width: auto;
  border: 1px solid #BBC5CA;
  box-shadow: none;
  background: #FFF;
  padding: 4px 10px;
  font-size: inherit;
  color: #4C4C4C;
  border-radius: 4px;
}
input::placeholder {
  color: #C3C3C3;
}
input:focus {
  outline: none;
  border-color: #6FB8F8;
}
input:disabled {
  background: #F6F6F6;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px #FFF inset;
}
textarea {
  min-height: 30px;
  width: auto;
  border: 1px solid #BBC5CA;
  box-shadow: none;
  background: #FFF;
  padding: 10px;
  font-size: inherit;
  color: #4C4C4C;
  border-radius: 4px;
  line-height: 1.3;
}
textarea::placeholder {
  color: #C3C3C3;
}
textarea:focus {
  outline: none;
  border-color: #6FB8F8;
}
textarea:disabled {
  background: #F6F6F6;
}

/*# sourceMappingURL=style-sheet-helpers.css.map*/
