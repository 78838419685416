.tabs {
  height: 100%;
  width: 100%;
}
.tabs-header {
  border-bottom: 1px solid #E0E0E0;
  height: 40px;
  width: 100%;
  padding: 0 15px;
}
.tabs-body {
  width: 100%;
  height: calc(100% - 40px);
}
