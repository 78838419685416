.left {
  display: inline-block;
  width: 60%;
}
.right {
  display: inline-block;
  width: 40%;
}
.external-id-input {
  display: inline-block;
  width: 200px;
  margin-right: 10px;
}
.external-id-edit {
  padding-left: 10px;
}
.external-id {
  width: 100%;
}
