.tab-button {
  position: relative;
  float: left;
  height: 34px;
  line-height: 34px;
  margin-right: 20px;
  font-size: 14px;
  cursor: pointer;
  color: #666;
}
.tab-button:hover {
  color: #8d60b5;
}
.tab-button:after {
  content: "";
  height: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  background: #8d60b5;
}
.tab-button.selected {
  color: #8d60b5;
}
.tab-button.selected:after {
  height: 4px;
}
.file-tab-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
  position: relative;
}
.file-tab-button.selected {
  color: #8d60b5;
  background-color: #FFF;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-left: 1px solid #C3C3C3;
  border-top: 1px solid #C3C3C3;
  border-right: 1px solid #C3C3C3;
  padding-left: 10px;
  padding-right: 10px;
  bottom: -1px;
}
.file-tab-button.selected:after {
  background: none;
}
