.button {
  vertical-align: middle;
  padding: 0 20px 0 0;
}
.row {
  border-bottom: 1px solid #E0E0E0;
}
.sync-description {
  padding-bottom: 10px;
}
.product-import-description {
  padding: 10px 0;
}
.jet-ss-header {
  font-size: 18px;
}
