.dropdown-icon-button {
  display: inline-block;
  float: none !important;
}
.dropdown-icon-button .dropdown-menu {
  padding: 0;
}
.icon > i {
  font-size: 20px;
}
.icon > svg {
  padding: 5px;
  width: 20px;
  height: 20px;
}
