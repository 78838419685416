.conclusion-content {
  text-align: center;
}
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.primary-text {
  font-weight: 600;
  font-size: 24px;
  color: #4C4C4C;
  margin: 0;
}
.secondary-text {
  font-weight: 400;
  font-size: 13px;
  color: #4C4C4C;
}
.success-icon > svg {
  fill: #6BA03A;
}
.failure-icon > svg {
  fill: #FF4016;
}
.svg-wrapper {
  margin-right: 10px;
}
.svg-wrapper > svg {
  height: 24px;
  width: 24px;
}
