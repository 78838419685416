.scan-to-print-toolbar {
  background: #EEEEEE;
  border-radius: 4px;
  -webkit-box-shadow: rgba(38, 38, 38, 0.2);
          box-shadow: rgba(38, 38, 38, 0.2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 80%;
  height: 80px;
  max-width: 700px;
  margin: 0 auto;
}
.scan-to-print-toolbar-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 15px;
  padding: 10px 0;
  height: 40px;
}
.scan-to-print-toolbar-row:last-child {
  padding-top: 0;
}
.scan-to-print-toolbar-title {
  font-size: 16px;
  padding-right: 20px;
  border-right: 1px solid #C3C3C3;
  height: 20px;
}
.scan-to-print-toolbar-title > span {
  display: block;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.scan-to-print-toolbar-order {
  font-size: 16px;
  margin: 5px auto 5px 0;
}
.scan-to-print-toolbar-scan-links {
  margin: 5px auto 5px 0;
  font-size: 13px;
}
.close-button {
  float: right;
  border-radius: 4px;
  position: relative;
  height: 30px;
  width: 30px;
  margin-left: 5px;
  padding-top: 10px;
}
.close-button > svg {
  height: 15px;
  width: 15px;
  fill: #909090;
  position: absolute;
  top: 10px;
  left: 10px;
}
.close-button:hover > svg {
  fill: #000000;
}
.connect-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-item-align: end;
      align-self: flex-end;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin-left: auto;
}
