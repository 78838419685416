.header {
  padding-top: 15px;
  letter-spacing: 2px;
  text-align: center;
}
.upgrade-button {
  padding-top: 40px;
}
.icon {
  position: absolute;
  top: -5px;
  width: 30px;
  height: 30px;
  fill: #4380e8;
}
.pricing-info {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 4px;
  min-height: 100px;
  border: 1px solid #C3C3C3;
  position: relative;
  width: 20%;
  color: #666666;
  margin-right: 40px;
}
.pricing-info.active {
  border: 4px solid #8d60b5;
  background-color: #f8f0ff;
}
@media (max-width: 1024px) {
  .pricing-info {
    min-height: auto;
    width: 320px;
  }
}
@media (max-width: 767px) {
  .pricing-info {
    min-height: auto;
  }
}
.pricing-info ul {
  margin-top: 40px;
  padding-left: 30px;
  padding-right: 5px;
  list-style: none;
}
.pricing-info ul li {
  margin-bottom: 10px;
  font-size: 13px;
  color: #666666;
  position: relative;
}
.pricing-info ul li a {
  text-decoration: underline;
}
.pricing-info h3 {
  color: #4380e8;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.pricing-info .price {
  text-align: center;
  font-size: 46px;
  font-weight: 400;
  color: #666666;
  position: relative;
}
.pricing-info .unit {
  font-size: 14px;
  text-align: center;
  display: block;
}
.pricing-info button {
  width: calc(100% - 20px);
  bottom: 10px;
  left: 10px;
  position: absolute;
}
@media (max-width: 1024px) {
  .pricing-info button {
    position: relative;
    margin-top: 20px;
  }
}
.benefits {
  margin-top: 45px;
  padding-right: 5px;
}
.benefit-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  font-size: 13px;
  color: #666666;
}
.benefit-wrapper a {
  text-decoration: underline;
}
.check {
  width: 11px;
  min-width: 11px;
  height: 8px;
  margin: auto 10px;
  fill: #4380e8;
}
