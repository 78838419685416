.seller-verification-form {
  font-size: 16px;
  padding: 20px 0;
}
.seller-verification-form-label {
  font-weight: 700;
  font-size: 14px;
  color: #666666;
  display: block;
}
.seller-verification-form-text-input {
  height: 40px;
  border-radius: 5px;
  width: 150px;
  display: block;
}
div.seller-verification-form-error {
  background-color: #FFD6CC;
  margin: -5px;
  padding: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
input.seller-verification-form-error {
  border-color: #FF4016;
}
.seller-verification-form-error p {
  color: #FF4016;
  font-size: 13px;
  padding-top: 3px;
  margin-bottom: -3px;
}
.seller-verification-form-text-input::-webkit-input-placeholder {
  color: #909090;
  font-size: 14px;
  font-weight: 400;
}
.seller-verification-form-text-input::-moz-placeholder {
  color: #909090;
  font-size: 14px;
  font-weight: 400;
}
.seller-verification-form-text-input::-ms-input-placeholder {
  color: #909090;
  font-size: 14px;
  font-weight: 400;
}
.seller-verification-form-text-input::placeholder {
  color: #909090;
  font-size: 14px;
  font-weight: 400;
}
