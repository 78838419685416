.date-picker {
  font-size: 0;
  display: inline-block;
  vertical-align: top;
}
.date-picker > input {
  border: 1px solid #E0E0E0;
  border-radius: 4px 0 0 4px;
  border-right: 0;
  height: 30px;
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  line-height: 30px;
  padding: 0 5px;
  width: 100px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.date-picker > input.full-width-input {
  width: calc(100% - 30px);
}
.date-picker-button-wrapper {
  font-size: 13px;
  display: inline-block;
  vertical-align: top;
  width: 30px;
}
.date-picker-button {
  width: 30px;
  border-radius: 0 4px 4px 0;
  padding: 0;
}
.icon {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}
