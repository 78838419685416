.section {
  border-bottom: 1px solid #E0E0E0;
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-size: 0;
}
.section:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}
.empty-item-content {
  color: #909090;
}
.item-content-with-background,
.shipping-option {
  background: #E6F0F5;
  font-size: 12px;
  color: #000000;
  border-radius: 4px;
  padding: 5px 10px;
  margin: 5px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.rate-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.shipping-option {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
}
.insured-value {
  padding-left: 10px;
  border-left: 1px solid #E0E0E0;
  margin-left: 10px;
  width: 40px;
  overflow: hidden;
}
.insured-name {
  width: calc(100% - 60px);
  display: inline-block;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.section-column {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}
.section-column:nth-child(even) {
  padding-left: 10px;
}
.section-column:nth-child(odd) {
  padding-right: 10px;
}
.item {
  margin-bottom: 5px;
}
.item:last-child {
  margin-bottom: 0;
}
.item-label {
  font-size: 11px;
  color: #909090;
}
.item-content {
  font-size: 14px;
  color: #000000;
}
