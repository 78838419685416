.billing-note {
  margin: 30px auto;
  width: 550px;
}
.billing-note p {
  color: #666666;
  font-size: 15px;
}
.footer {
  border-top: 2px solid #F6F6F6;
  padding: 20px 20px 10px;
}
.billing-error {
  width: 550px;
  margin: 10px auto 15px;
  list-style: none;
  color: #FF4016;
}
