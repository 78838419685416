.loading-indicator {
  height: 100%;
  width: 100%;
}
.loading-indicator .circle {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  float: left;
  width: 30%;
  margin-right: 5%;
  padding-top: 30%;
  opacity: 0.25;
  -webkit-animation-name: opacity-animation;
          animation-name: opacity-animation;
  -webkit-animation-duration: 1500ms;
          animation-duration: 1500ms;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.loading-indicator .circle:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.loading-indicator .circle:first-child {
  -webkit-animation-delay: 187.5ms;
          animation-delay: 187.5ms;
}
.loading-indicator .circle:nth-child(2) {
  -webkit-animation-delay: 1875ms;
          animation-delay: 1875ms;
}
.loading-indicator .circle:last-child {
  margin-right: 0;
  -webkit-animation-delay: 3562.5ms;
          animation-delay: 3562.5ms;
}
.loading-indicator.green .circle:after {
  background: #3F8200;
}
.loading-indicator.shipengine .circle:after {
  background: #724599;
}
.loading-indicator.white .circle:after {
  background: #ffffff;
}
.loading-indicator.grey .circle:after {
  background: #4C4C4C;
}
@-webkit-keyframes opacity-animation {
  0% {
    opacity: 0.25;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}
@keyframes opacity-animation {
  0% {
    opacity: 0.25;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}
.circle {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  float: left;
  width: 30%;
  margin-right: 5%;
  padding-top: 30%;
  opacity: 0.25;
  -webkit-animation-name: opacity-animation;
          animation-name: opacity-animation;
  -webkit-animation-duration: 1500ms;
          animation-duration: 1500ms;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.circle:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.circle:first-child {
  -webkit-animation-delay: 187.5ms;
          animation-delay: 187.5ms;
}
.circle:nth-child(2) {
  -webkit-animation-delay: 1875ms;
          animation-delay: 1875ms;
}
.circle:last-child {
  margin-right: 0;
  -webkit-animation-delay: 3562.5ms;
          animation-delay: 3562.5ms;
}
