.package-picker.has-error .package-picker-select select {
  border-color: #FF4016;
}
.package-picker.fill-all-available-space .package-picker-select {
  width: 100%;
}
.package-picker-select {
  height: 30px;
  float: left;
}
.error-messages {
  margin-top: 5px;
}
