.logo-preview {
  width: 260px;
  padding-top: 20px;
  padding-bottom: 10px;
}
.header-row-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.tab-content {
  padding: 10px 15px;
}
.text-input,
.color-input {
  clear: both;
  width: 300px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #262626;
}
.text-input input,
.color-input input {
  color: #262626;
  font-weight: 400;
}
.text-input .hex-color-input,
.color-input .hex-color-input {
  width: 253px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: top;
}
.text-input .color-preview,
.color-input .color-preview {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: inline-block;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  vertical-align: top;
  border: 1px #C3C3C3 solid;
}
.loading {
  height: 30px;
}
.loading-wrapper {
  text-align: center;
  width: "100%";
}
.loading-indicator {
  height: 30px;
  width: 30px;
  margin-right: auto;
  margin-left: auto;
}
.error h3 {
  margin-top: 10px;
  font-size: 14px;
}
.error li {
  list-style-type: none;
  margin-left: 10px;
}
.grouped-text-input {
  clear: both;
  width: 300px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #262626;
}
.grouped-text-input input {
  color: #262626;
  font-weight: 400;
}
.grouped-text-input > div {
  margin-bottom: 5px;
}
.store-logo-upload,
.dropzone {
  width: 300px;
  min-height: 60px;
  background-color: #EEEEEE;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
}
.store-logo-upload .store-logo-upload-title,
.dropzone .store-logo-upload-title {
  font-size: 10px;
  color: #909090;
}
.store-logo-upload .description,
.dropzone .description {
  margin-top: 45px;
  margin-bottom: 30px;
  color: #666666;
}
.store-logo-upload .store-logo-upload-button,
.dropzone .store-logo-upload-button {
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 300px;
}
.dropzone-reject {
  border: 3px #FF4016;
}
.order-info-input {
  width: 300px;
}
.order-info-input .order-info-checkbox {
  margin: 20px 0;
}
.order-info-input .order-info-slider {
  width: 125px;
  float: left;
  display: block;
  margin: 5px;
}
.checkbox {
  margin: 20px 0;
}
.action-buttons > a {
  display: inline-block;
  margin: 10px 5px;
}
