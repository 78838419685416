.ipaas-data-driven-modal .ipaas-modal-logo {
  margin-bottom: 10px;
  width: 250px;
}
.ipaas-data-driven-modal .ipaas-modal-logo .logo {
  height: 50px;
}
.ipaas-data-driven-modal .ipaas-modal-logo.center {
  margin-left: auto;
  margin-right: auto;
}
.ipaas-data-driven-modal .ipaas-modal-logo.small > svg {
  width: 150px;
  margin: auto;
  display: block;
}
.ipaas-data-driven-modal legend {
  margin: 0 0 10px;
  font-size: 18px;
}
.ipaas-data-driven-modal * {
  font-size: 14px;
}
.ipaas-data-driven-modal .form-group .checkbox {
  margin: 0;
}
.ipaas-data-driven-modal .help-block {
  color: #909090;
}
