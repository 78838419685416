.invoice-number {
  width: 20%;
  text-align: center;
}
.invoice-date {
  width: 20%;
  text-align: center;
}
.total {
  width: 20%;
  padding-right: 12px;
  text-align: right;
}
.invoice-row {
  font-size: 12px;
}
.invoice-row .btn {
  font-size: 12px;
  white-space: normal;
  padding: 3px;
  margin-right: 6px;
}
.invoice-table {
  margin-bottom: 10px;
  width: 100%;
  clear: both;
  margin-top: 10px;
  padding: 5px 0;
  background-color: #FFF;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: #E0E0E0 1px solid;
}
.invoice-table tr > th {
  padding: 5px;
  background-color: #EEEEEE;
}
.invoice-table tr > td {
  padding: 5px;
  border-bottom: #E0E0E0 1px solid;
}
.table-wrapper {
  overflow: scroll;
  overflow-x: hidden;
  height: 250px;
  width: 75%;
}
.download-button-column {
  border-radius: 4px;
  width: 25%;
  text-align: center;
}
.download-button {
  width: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0;
  margin: auto;
}
.download-button svg {
  width: 16px;
  height: 16px;
}
