.order-picker-container {
  background-color: #ffffff;
}
.order-picker-header {
  font-size: 14px;
  color: #909090;
  margin-bottom: 10px;
}
.order-listitem-container {
  height: 65px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: solid #E0E0E0 1px;
  margin-bottom: -1px;
  cursor: pointer;
}
.order-listitem-container:hover {
  background: #EEF8FD;
}
.order-detail-index {
  height: 63px;
  width: 30px;
  text-align: center;
  line-height: 63px;
  border-right: solid #E0E0E0 1px;
  min-width: 30px;
  font-size: 18px;
  font-weight: 700;
}
.order-detail-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(100% - 30px);
}
.detail-left-container {
  height: 100%;
  padding: 10px;
  width: 100%;
  overflow: hidden;
}
.detail-right-container {
  height: 100%;
  padding: 10px;
}
.order-detail-ordernumber {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 18px;
  color: #262626;
  margin-top: -3px;
  margin-bottom: 4px;
}
.order-detail-createdate {
  font-size: 16px;
  color: #909090;
}
.order-detail-orderstatus {
  background: #E6F0F5;
  border-radius: 3px;
  white-space: nowrap;
  padding: 5px 10px;
  text-align: center;
  font-size: 12px;
  color: #262626;
}
