.address-line-1 {
  width: calc(75% - 10px);
}
.address-line-2 {
  width: calc(25%);
}
.address-city {
  width: calc(40% - 10px);
}
.address-state {
  width: calc(20% - 10px);
}
.address-postal-code {
  width: calc(20% - 10px);
}
.address-country {
  width: calc(20%);
}
