.shipping-service-picker {
  width: 100%;
}
.shipping-service-picker.show-sort-options .sort-order-wrapper {
  display: block;
}
.shipping-service-picker.show-sort-options.fill-all-available-space .content {
  width: calc(100% - 35px);
}
.shipping-service-picker.has-error .service-selector-input {
  border-color: #FF4016;
}
.shipping-service-picker.fill-all-available-space .content {
  width: 100%;
}
.shipping-service-picker-content {
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.sort-order-wrapper {
  margin-left: 5px;
  width: 25px;
  height: 25px;
  display: none;
  position: relative;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}
.sort-order-wrapper :global(.link-button) {
  width: 25px;
  height: 25px;
}
.dropdown-header {
  background: #F6F6F6;
  padding: 5px;
  border-bottom: solid 1px #C3C3C3;
  color: #666666;
  font-size: 12px;
}
.dropdown-content {
  padding: 5px 0;
}
option:disabled {
  color: #C3C3C3;
}
.autofunding-button {
  position: relative;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  margin-left: 10px;
}
.error-messages {
  margin-top: 5px;
}
