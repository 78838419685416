.service-display {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.service-display i {
  color: #4C4C4C;
  margin-left: 10px;
}
.service-display > * {
  display: inline-block;
}
