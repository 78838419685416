.marketplaces-page {
  min-width: 0 !important;
}
.marketplaces-page .modal-backdrop {
  display: none;
}
.marketplaces-page #topnav {
  display: none;
}
.marketplaces-page .modal-dialog {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  height: 100% !important;
  width: 100% !important;
}
.marketplaces-page .modal-content {
  border: 0;
  border-radius: 0;
}
.marketplaces-page .modal-header {
  display: none;
}
.marketplaces-page .modal-body.scrollable {
  top: 0;
}
