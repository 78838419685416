.text-modal-content {
  font-size: 16px;
}
.text-modal-footer {
  text-align: right;
}
.text-modal-footer button {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}
.text-modal-footer button:last-child {
  margin-right: 0;
}
