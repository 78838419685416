.container {
  position: relative;
}
.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 2px solid #F6F6F6;
  padding: 10px 15px 20px;
}
.message {
  padding-left: 20px;
  width: 60%;
  display: block;
  float: left;
}
.message p {
  font-size: 15px;
  color: #666666;
}
.billing-note {
  margin: 30px auto;
  padding: 0 20px;
}
.billing-note p {
  color: #666666;
  font-size: 15px;
}
.footer {
  border-top: 2px solid #F6F6F6;
  padding: 20px 20px 10px;
}
.billing-error {
  width: calc(100% - 40px);
  margin: 10px auto 15px;
  list-style: none;
  color: #FF4016;
}
.textarea-container {
  margin: 0 20px;
  width: calc(100% - 40px);
  font-size: 15px;
}
.textarea-container textarea {
  font-size: 15px;
}
.thank-you-container {
  left: 0;
  font-size: 15px;
  width: 100%;
  height: 100%;
  background: #ffffff;
  padding: 10px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
