.connect-install-modal {
  width: 465px;
  margin: 0 auto;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.connect-install-instructions {
  font-size: 16px;
  padding: 20px;
  text-align: center;
  background-color: #ffffff;
}
.instructions-head {
  text-align: left;
}
.btn-wrap {
  padding: 20px;
}
.kb-seperator-wrap {
  padding: 5px 10px;
  display: inline-block;
}
.kb-seperator {
  display: inline-block;
  width: 2px;
  height: 16px;
  top: 2px;
  position: relative;
  background-color: #A2ACB1;
}
