.credit-card {
  height: 40px;
  width: auto;
  background: #ffffff;
  border-radius: 5px;
  float: left;
}
.card-image {
  width: 65px;
  float: left;
}
.last-four {
  font-size: 20px;
  font-weight: 600;
  line-height: 40px;
  float: left;
  padding: 0 15px;
}
.center {
  text-align: center;
}
