.percentage-input {
  position: relative;
}
.percentage-input > input {
  padding-right: 17px;
  display: block;
  text-align: right;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #909090;
  width: 100%;
  -moz-appearance: textfield;
}
.percentage-input > input:invalid {
  border: 1px solid #FF4016;
}
.percentage-input > input::-webkit-outer-spin-button,
.percentage-input > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.percentage-input:before {
  content: "%";
  position: absolute;
  top: 2px;
  right: 4px;
  background: #ffffff;
  font-weight: bold;
  line-height: 37px;
  border-radius: 4px;
}
