.acumatica-instruction-header {
  padding-bottom: 5px;
}
.acumatica-integration-body {
  padding: 10px 15px;
}
.acumatica-integration-body .acumatica-instruction-list li {
  padding: 5px 0;
}
.acumatica-integration-body .integration-revoke-instructions {
  padding-top: 10px;
}
.acumatica-integration-body .info {
  color: #909090;
}
.integration-modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.footer-actions {
  width: 100%;
  -ms-flex-item-align: center;
      align-self: center;
}
.footer-actions .close-button {
  float: left;
}
.footer-actions .connect-button,
.footer-actions .revoke-button {
  float: right;
}
