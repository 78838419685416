.provider-section {
  border: 1px solid #E0E0E0;
  background: #ffffff;
  margin-bottom: 7px;
}
.provider-rows {
  padding: 7px 14px;
}
.provider-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.provider-row:not(:last-child) {
  margin-bottom: 7px;
}
.provider-row-logo {
  height: 30px;
  width: 30px;
  min-width: 30px;
}
.provider-row-logo > img,
.provider-row-logo > svg {
  height: 100%;
  width: 100%;
}
.provider-row-name {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin-left: 14px;
  font-size: 16px;
}
.provider-services,
.provider-settings,
.insurance-row-balance,
.insurance-row-add-funds {
  width: 200px;
  min-width: 200px;
  margin-left: 14px;
}
.balance-amount {
  color: #7DCA36;
  margin-left: 7px;
}
