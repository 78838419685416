.float-input {
  border-radius: 4px;
  position: relative;
  height: 42px;
  padding: 0;
  line-height: 28px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  background: #FFF;
}
.float-input.error > input {
  border: solid 1px #FF4016;
}
.float-input.disabled {
  background: #F6F6F6;
}
.float-input.disabled .float-label-container-background {
  background: transparent;
}
.float-input > input {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 12px 10px 0;
  height: 100%;
  background: transparent;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  font-size: 16px;
  display: block;
}
.required-indicator {
  position: absolute;
  top: 0;
  right: 0;
}
