.scale-information {
  background: #ffffff;
  padding: 10px;
  border-bottom: 1px solid #E0E0E0;
  position: relative;
  height: 125px;
}
.scale-information:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  background: rgba(0, 0, 0, 0.2);
}
.weight-display-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: -6px;
}
.display-weight-column {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.display-weight-number {
  font-size: 42px;
}
.display-weight-unit {
  font-size: 20px;
  font-weight: 700;
  padding-left: 3px;
}
.scale-display-order-weight {
  text-align: right;
  color: #909090;
  padding-right: 10px;
}
.title {
  text-align: left;
  line-height: 30px;
  padding-left: 10px;
}
.weight-display {
  width: 100%;
  padding: 0 10px;
  font-size: 36px;
  text-align: right;
  color: #909090;
}
.weight-display.error {
  color: #FF4016;
  font-size: 16px;
  text-align: center;
}
.weight-display.reading {
  color: #909090;
}
.weight-display.zero-weight {
  color: #FF4016;
}
.weight-display.scale-weight {
  color: #72BCE0;
}
.error-link-wrapper {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  font-size: 13px;
}
.scan-to-print-scale {
  position: relative;
  height: 290px;
}
.alert-wrapper {
  bottom: 10px;
  position: absolute;
  width: 100%;
}
.zero-weight-alert {
  width: 100%;
  text-align: center;
  color: #FF8000;
}
.no-scale-content {
  border-bottom: 1px solid #E0E0E0;
  height: 125px;
  width: 100%;
  background: #EEEEEE;
}
.no-scale-text {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: center;
  padding: 0 40px;
  font-size: 13px;
  color: #4C4C4C;
}
.use-scale-wrapper {
  padding: 5px;
  text-align: center;
  font-size: 14px;
}
.use-scale-wrapper .scale-weight-message {
  padding: 10px;
  margin: auto;
  font-size: 12px;
  font-style: italic;
  color: #909090;
}
.error-wrapper {
  padding: 5px 10px;
  background-color: #FFE9E3;
  color: #FF4016;
  margin: 10px;
  border-radius: 4px;
}
