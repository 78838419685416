.modal {
  width: 700px;
}
.header {
  text-align: center;
  padding-left: 30px;
}
.body {
  height: 480px !important;
}
.connections-modal-content-wrapper {
  width: 100%;
  padding: 15px 50px;
}
.connections-modal-integration-selector-wrapper {
  padding-top: 15px;
}
.connections-modal-integration-selector-wrapper button {
  display: inline-block;
  width: 160px;
  height: 90px;
  margin: 10px;
  padding: 10px;
  position: relative;
  border: 2px solid #E0E0E0;
}
.connections-modal-integration-selector-wrapper button:hover {
  border-color: #724599;
}
.connections-modal-integration-selector-wrapper button.selected {
  border-color: #FF8000;
}
.connections-modal-integration-selector-wrapper button.not-public {
  border-color: #FF4016;
}
.connections-modal-search input {
  color: #262626;
  font-weight: 400;
}
