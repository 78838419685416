.progress-bar-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  background: #C3C3C3;
}
.progress-bar-wrapper.indefinite-progress-bar .progress-bar {
  position: absolute;
  top: 0;
  -webkit-transition: none;
  transition: none;
  -webkit-animation: indefinite-animation 1000ms linear infinite;
          animation: indefinite-animation 1000ms linear infinite;
}
.progress-bar {
  height: 100%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@-webkit-keyframes indefinite-animation {
  0% {
    left: 0;
    right: 100%;
  }
  20% {
    left: 0;
    right: 60%;
  }
  80% {
    left: 60%;
    right: 0;
  }
  100% {
    left: 100%;
    right: 0;
  }
}
@keyframes indefinite-animation {
  0% {
    left: 0;
    right: 100%;
  }
  20% {
    left: 0;
    right: 60%;
  }
  80% {
    left: 60%;
    right: 0;
  }
  100% {
    left: 100%;
    right: 0;
  }
}
