.billing-footer {
  padding: 19px 30px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  border-top: 1px #E0E0E0 solid;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 70px;
}
.space-right-button {
  margin-left: 10px;
}
.has-footer {
  height: calc(100% - 48px);
}
