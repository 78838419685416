.fedex-upload-buttons {
  padding: 5px 0;
}
.fedex-settings-labels {
  padding: 5px 0 5px 2px;
}
.fedex-information-below {
  width: 100%;
  text-align: center;
  padding-bottom: 10px;
}
.fedex-trademark-text {
  padding-top: 10px;
}
.fedex-city-field {
  padding-left: 0 !important;
}
.fedex-zip-field {
  padding-right: 0 !important;
}
.signature-error-container,
.letterhead-error-container {
  margin-top: 5px;
  margin-bottom: 5px;
}
.signature-error,
.letterhead-error {
  padding: 5px 0 5px 10px;
}
.view-signature,
.view-letterhead {
  padding: 5px 0;
}
.import-names,
.import-letterhead-names {
  margin-left: 26px;
  margin-top: 5px;
}
.import-names ul,
.import-letterhead-names ul {
  margin: 0;
  list-style: none;
}
.import-names ul li .progress,
.import-letterhead-names ul li .progress {
  height: 7px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 0;
}
.import-names ul li .icon-remove,
.import-letterhead-names ul li .icon-remove {
  cursor: pointer;
  margin-top: 5px;
}
.fedex-form-margins {
  margin: 0 3%;
}
.fedex-left-column-right-margin {
  width: 47%;
  margin-right: 3%;
}
.fedex-right-column-left-margin {
  width: 47%;
  margin-left: 3%;
}
.letterhead-upload-filename,
.signature-upload-filename {
  padding-left: 5px;
  color: #4791CE;
}
.fedex-upload-instructions {
  padding-bottom: 5px;
}
.fedex-uk-upload-forms {
  padding-top: 10px;
  margin: 0 20px;
}
