.wallet-group {
  border: 1px solid #E0E0E0;
  margin-bottom: 10px;
}
.wallet-group-header {
  background-color: #F6F6F6;
  padding: 7px;
}
.lbl-balance {
  color: #6BA03A;
}
.wallet-group-members {
  padding: 4px 0 0;
}
.wallet-group-members.hidden {
  display: none;
}
.wallet-group-member-wrap {
  border-bottom: 1px solid #E0E0E0;
  float: left;
  width: calc(100% - 38px);
}
.wallet-group-member {
  padding: 4px;
}
.wallet-group-member:last-child .wallet-group-member-wrap {
  border-bottom: none;
}
.wallet-group-member .provider-or-service-name {
  width: calc(300px + 10px);
}
.wallet-group-member-spacer {
  float: left;
  width: 38px;
  height: 10px;
}
.wallet-name {
  font-size: 16px;
  margin-left: 10px;
  line-height: 26px;
}
.shipsurance-balance-controls {
  line-height: 28px;
  margin-left: 50px;
}
.carrier-logo-image-wrapper svg,
.carrier-logo-image-wrapper img {
  width: 25px;
  height: 25px;
  overflow: visible;
}
.manage-services-link {
  padding: 0;
  margin-bottom: 4px;
  border: 0;
}
.balance-controls {
  margin-left: 35px;
}
.purchase-postage {
  margin-left: 10px;
}
.purchase-postage:disabled {
  color: #BBC5CA;
}
.purchase-postage:disabled:hover {
  text-decoration: none;
}
.provider-or-service-name {
  width: 300px;
}
.provider-account-pending {
  color: #D8E2E7;
}
