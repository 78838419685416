.weight-input-content {
  height: 30px;
}
.error-messages {
  margin-top: 5px;
}
.weight-number-inputs {
  float: left;
}
.weight-number-input {
  float: left;
}
.number-input-wrapper {
  float: left;
}
.number-input-wrapper > div {
  width: 90px;
}
.weight-number-input-label {
  float: left;
  margin: 0 5px;
  line-height: 30px;
  font-size: 10px;
  color: #666666;
}
.scale-button-wrapper {
  float: left;
}
.scale-button-wrapper > button {
  background: #E6F0F5;
}
.scale-button-wrapper > button:hover {
  background: #E6F0F5;
}
.scale-button {
  float: left;
  border-radius: 4px 0 0 4px;
  height: 30px;
  padding: 6px;
}
.scale-button svg {
  fill: #666666;
  height: 16px;
  width: 16px;
}
.loading-indicator-wrapper {
  width: 20px;
  height: 16px;
}
.scale-dropdown-button {
  float: left;
  height: 30px;
  padding: 0 2px;
  border-radius: 0 4px 4px 0;
  margin-left: -1px;
}
.scale-dropdown-button > i {
  line-height: 30px;
}
