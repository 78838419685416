.integration-selector {
  height: 100%;
  width: 100%;
  text-align: center;
}
.integration-selector .integration-button {
  display: inline-block;
  width: 170px;
  height: 110px;
  margin: 10px;
  padding: 10px 3px;
  position: relative;
  border: 2px solid #E0E0E0;
  vertical-align: top;
}
.integration-selector .integration-button.selected {
  border-color: #FF8000;
}
.integration-selector .integration-button.not-public {
  border-color: #FF4016;
}
.integration-selector .integration-button:hover {
  border-color: #2C76B3;
}
.not-public-text {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0 2px;
  color: #FF4016;
  font-size: 12px;
  text-align: left;
  text-transform: uppercase;
}
.integration-logo {
  width: 100%;
  height: 100%;
  padding: 10px 10px 15px;
}
.integration-name {
  position: relative;
  top: -15px;
  font-weight: bold;
  font-size: 11px;
}
