.input-button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.input-button-container input {
  height: 30px;
  width: auto;
  border: 1px solid #BBC5CA;
  border-radius: 4px 0 0 4px;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: #ffffff;
  padding: 4px 10px;
  font-size: 16px;
  color: #4C4C4C;
}
.input-button-container input::-webkit-input-placeholder {
  color: #C3C3C3;
}
.input-button-container input::-moz-placeholder {
  color: #C3C3C3;
}
.input-button-container input::-ms-input-placeholder {
  color: #C3C3C3;
}
.input-button-container input::placeholder {
  color: #C3C3C3;
}
.input-button-container input:focus {
  outline: none;
  border-color: #6FB8F8;
}
.input-button-container button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
