.grid-container {
  width: 100%;
  display: grid;
  grid-template-columns: [data] auto;
  grid-template-rows: [header] 29px [content] repeat(auto-fill, 33px);
  font-size: 14px;
  border: 1px solid #E0E0E0;
  background-color: #ffffff;
}
.table-header-row {
  background: -webkit-gradient(linear, left top, left bottom, from(#F6F6F6), to(#EEEEEE));
  background: linear-gradient(180deg, #F6F6F6 0%, #EEEEEE 100%);
  -webkit-box-shadow: inset 0 2px 0 0 #ffffff;
          box-shadow: inset 0 2px 0 0 #ffffff;
  border-radius: 3px 3px 0 0;
  border: 1px solid #E0E0E0;
  color: #1E282D;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  font-size: 14px;
  font-weight: 600;
  padding: 0 6px 0 7px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-row: header / 1;
  grid-column: data / 1;
}
.username-header,
.username-col {
  width: 30%;
}
.name-header,
.name-col {
  width: 15%;
}
.email-header,
.email-col {
  width: 30%;
}
.last-login-header,
.last-login-col {
  width: 10%;
}
.status-header,
.status-col {
  width: 7%;
}
.actions-header,
.actions-col {
  width: 7%;
  text-align: center;
}
.table-data-row {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  grid-column: data / 1;
  padding: 7px 6px 7px 4px;
  margin-right: 8px;
  border-top: 1px solid #E0E0E0;
}
.table-data-row > div {
  padding-right: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-data-row > .actions-col {
  padding-right: 0;
}
.no-data-row {
  text-align: center;
  border-radius: 0 0 3px 3px;
  border-bottom: 1px solid #E0E0E0;
  background-color: #ffffff;
  grid-column: data / 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
