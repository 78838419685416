.delivery-options-settings {
  height: 100%;
}
.delivery-options-settings > h1 {
  padding-bottom: 10px;
  border-bottom: 1px solid #C3C3C3;
  margin-bottom: 10px;
}
.loading-indicator-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  width: 100%;
}
.loading-indicator {
  height: 100px;
  width: 100px;
}
.explanation-and-key {
  font-size: 14px;
  margin-bottom: 10px;
}
.explanation-and-key > div {
  margin-bottom: 5px;
}
.explanation-and-key > div:last-child {
  margin-bottom: 0;
}
.action-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.action-buttons > * {
  margin-right: 10px;
}
.delivery-options-list {
  margin-top: 10px;
}
.edit-delivery-option-modal,
.preview-modal {
  max-height: 100%;
}
.edit-delivery-option-modal :global .modal-body,
.preview-modal :global .modal-body {
  padding: 0;
}
.edit-delivery-option-modal :global .modal-dialog,
.preview-modal :global .modal-dialog {
  max-height: 100%;
}
.edit-delivery-option-modal :global .modal-content,
.preview-modal :global .modal-content {
  max-height: 100%;
}
.edit-delivery-option-modal-body {
  height: calc(100% - 52px);
}
.list-placeholder {
  border: 1px solid #E0E0E0;
  border-top: 0;
  padding: 80px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  justify-items: center;
}
.list-placeholder h1 {
  margin-bottom: 20px;
}
.list-placeholder p:not(:last-child) {
  margin-bottom: 30px;
}
.beta-disclaimer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px;
}
.beta-tag {
  color: #FFB56A;
  background: #FFF4E9;
  border: 1px solid #FFB56A;
  border-radius: 4px;
  padding: 4px 8px;
  margin-right: 8px;
}
