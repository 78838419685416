.open .select-button-chevron > svg {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.select {
  display: inline-block;
}
.select-button {
  position: relative;
  padding: 0 30px 0 10px;
  text-align: left;
  letter-spacing: 0;
  max-width: 100%;
}
.select-button.has-label {
  height: 42px;
  padding: 5px 30px 5px 10px;
}
.select-button-label {
  font-size: 11px;
  line-height: 11px;
  color: #909090;
}
.select-button-content {
  font-size: 15px;
  line-height: 15px;
}
.select-button-chevron {
  position: absolute;
  height: 30px;
  width: 30px;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.select-button-chevron svg {
  height: 100%;
  width: 100%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.select-dropdown-menu {
  border-radius: 4px;
  margin-top: 5px;
  border: 1px solid #E0E0E0;
  max-height: 300px;
  overflow: auto;
}
.dropdown-menu-item {
  display: block;
  width: 100%;
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #E0E0E0;
  font-size: 13px;
  color: #000000;
  white-space: nowrap;
  background: #ffffff;
}
.dropdown-menu-item:hover {
  background: #EEF8FD;
}
.dropdown-menu-item.selected {
  background: #EEF8FD;
}
.dropdown-menu-item.selected:hover {
  background: #EEF8FD;
}
.dropdown-menu-item.has-custom-renderer {
  padding: 0;
}
.dropdown-menu-item:last-child {
  border-bottom: 0;
}
