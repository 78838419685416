.shipengine-carriers-section-title {
  float: left;
}
.shipengine-carriers-modal-open-button {
  float: right;
  border-radius: 14px;
}
.shipengine-carriers-modal-open-button .balance {
  font-weight: 600;
}
.shipengine-carriers-modal-open-button a {
  margin-left: 20px;
}
