.calendar-date-picker {
  line-height: 1;
}
.day-placeholder,
.day-button {
  display: inline-block;
  vertical-align: top;
}
.day-button {
  text-align: center;
  line-height: 20px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  color: #262626;
}
.day-button.selected,
.day-button:hover {
  background: #046BC0;
  color: #FFF;
}
.day-button:disabled {
  cursor: default;
  color: #909090;
}
.day-button:disabled:hover {
  color: #909090;
  background: transparent;
}
.day-button:disabled.selected {
  background: #909090;
  color: #FFF;
}
.icon-wrapper {
  padding: 8px;
}
.icon-wrapper > svg {
  height: 14px;
  width: 14px;
}
