.unstyled-button {
  border: none;
  -o-border-image: none;
     border-image: none;
  padding: 0;
}
/*
 * * * * * * * * * * * * * * * * * * *
 * BUTTONS
 * * * * * * * * * * * * * * * * * * *
 */
.button {
  border: none;
  -o-border-image: none;
     border-image: none;
  padding: 0;
  position: relative;
  display: inline-block;
  width: auto;
  height: 30px;
  line-height: 28px;
  font-size: 13px;
  font-weight: 400;
  padding: 0 15px;
  border-radius: 3px;
  cursor: pointer;
  letter-spacing: 0.03em;
  background: #EEEEEE;
  color: #4C4C4C !important;
  border-top: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
  -webkit-box-shadow: 0 1px 1px rgba(76, 76, 76, 0.3);
          box-shadow: 0 1px 1px rgba(76, 76, 76, 0.3);
  -webkit-transition: none !important;
  transition: none !important;
  text-decoration: none;
}
.button:hover {
  background: #f2f2f2;
  -webkit-box-shadow: 0 2px 3px rgba(76, 76, 76, 0.5);
          box-shadow: 0 2px 3px rgba(76, 76, 76, 0.5);
  border-bottom-color: #f0f0f0;
  border-top-color: #f2f2f2;
  cursor: pointer;
  text-decoration: none !important;
}
.button:focus {
  outline: 0;
}
.button:focus:before {
  content: "";
  display: block;
  position: absolute;
  top: -3px;
  right: -2px;
  bottom: -3px;
  left: -2px;
  border-top: 3px solid rgba(201, 230, 255, 0.7);
  border-right: 2px solid rgba(201, 230, 255, 0.7);
  border-bottom: 3px solid rgba(201, 230, 255, 0.7);
  border-left: 2px solid rgba(201, 230, 255, 0.7);
  border-radius: 3px;
  z-index: 1;
}
.button:active,
.button.selected,
.button.is-loading {
  background: #e9e9e9;
  border-top-color: #cacaca;
  border-top-width: 2px;
  border-bottom-color: #EEEEEE;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.button:disabled,
.button.disabled,
.button[disabled=disabled] {
  opacity: 0.4;
}
.button:disabled:hover,
.button.disabled:hover,
.button[disabled=disabled]:hover,
.button:disabled:active,
.button.disabled:active,
.button[disabled=disabled]:active {
  cursor: default;
  background: #EEEEEE;
  -webkit-box-shadow: 0 1px 1px rgba(76, 76, 76, 0.3);
          box-shadow: 0 1px 1px rgba(76, 76, 76, 0.3);
}
.button-dark {
  border: none;
  -o-border-image: none;
     border-image: none;
  padding: 0;
  position: relative;
  display: inline-block;
  width: auto;
  height: 30px;
  line-height: 28px;
  font-size: 13px;
  font-weight: 400;
  padding: 0 15px;
  border-radius: 3px;
  cursor: pointer;
  letter-spacing: 0.03em;
  background: #E0E0E0;
  color: #4C4C4C !important;
  border-top: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
  -webkit-box-shadow: 0 1px 1px rgba(76, 76, 76, 0.3);
          box-shadow: 0 1px 1px rgba(76, 76, 76, 0.3);
  -webkit-transition: none !important;
  transition: none !important;
  text-decoration: none;
}
.button-dark:hover {
  background: #e8e8e8;
  -webkit-box-shadow: 0 2px 3px rgba(76, 76, 76, 0.5);
          box-shadow: 0 2px 3px rgba(76, 76, 76, 0.5);
  border-bottom-color: #e3e3e3;
  border-top-color: #e8e8e8;
  cursor: pointer;
  text-decoration: none !important;
}
.button-dark:focus {
  outline: 0;
}
.button-dark:focus:before {
  content: "";
  display: block;
  position: absolute;
  top: -3px;
  right: -2px;
  bottom: -3px;
  left: -2px;
  border-top: 3px solid rgba(201, 230, 255, 0.7);
  border-right: 2px solid rgba(201, 230, 255, 0.7);
  border-bottom: 3px solid rgba(201, 230, 255, 0.7);
  border-left: 2px solid rgba(201, 230, 255, 0.7);
  border-radius: 3px;
  z-index: 1;
}
.button-dark:active,
.button-dark.selected,
.button-dark.is-loading {
  background: #d7d7d7;
  border-top-color: #bebebe;
  border-top-width: 2px;
  border-bottom-color: #E0E0E0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.button-dark:disabled,
.button-dark.disabled,
.button-dark[disabled=disabled] {
  opacity: 0.4;
}
.button-dark:disabled:hover,
.button-dark.disabled:hover,
.button-dark[disabled=disabled]:hover,
.button-dark:disabled:active,
.button-dark.disabled:active,
.button-dark[disabled=disabled]:active {
  cursor: default;
  background: #E0E0E0;
  -webkit-box-shadow: 0 1px 1px rgba(76, 76, 76, 0.3);
          box-shadow: 0 1px 1px rgba(76, 76, 76, 0.3);
}
.shipengine-primary-button {
  border: none;
  -o-border-image: none;
     border-image: none;
  padding: 0;
  position: relative;
  display: inline-block;
  width: auto;
  height: 30px;
  line-height: 28px;
  font-size: 13px;
  font-weight: 400;
  padding: 0 15px;
  border-radius: 3px;
  cursor: pointer;
  letter-spacing: 0.03em;
  background: #502278;
  color: #ffffff !important;
  border-top: 1px solid #502278;
  border-bottom: 1px solid #502278;
  -webkit-box-shadow: 0 1px 1px rgba(76, 76, 76, 0.3);
          box-shadow: 0 1px 1px rgba(76, 76, 76, 0.3);
  -webkit-transition: none !important;
  transition: none !important;
  text-decoration: none;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}
.shipengine-primary-button:hover {
  background: #623886;
  -webkit-box-shadow: 0 2px 3px rgba(76, 76, 76, 0.5);
          box-shadow: 0 2px 3px rgba(76, 76, 76, 0.5);
  border-bottom-color: #502278;
  border-top-color: #623886;
  cursor: pointer;
  text-decoration: none !important;
}
.shipengine-primary-button:focus {
  outline: 0;
}
.shipengine-primary-button:focus:before {
  content: "";
  display: block;
  position: absolute;
  top: -3px;
  right: -2px;
  bottom: -3px;
  left: -2px;
  border-top: 3px solid rgba(201, 230, 255, 0.7);
  border-right: 2px solid rgba(201, 230, 255, 0.7);
  border-bottom: 3px solid rgba(201, 230, 255, 0.7);
  border-left: 2px solid rgba(201, 230, 255, 0.7);
  border-radius: 3px;
  z-index: 1;
}
.shipengine-primary-button:active,
.shipengine-primary-button.selected,
.shipengine-primary-button.is-loading {
  background: #4c2072;
  border-top-color: #381854;
  border-top-width: 2px;
  border-bottom-color: #502278;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.shipengine-primary-button:disabled,
.shipengine-primary-button.disabled,
.shipengine-primary-button[disabled=disabled] {
  opacity: 0.4;
}
.shipengine-primary-button:disabled:hover,
.shipengine-primary-button.disabled:hover,
.shipengine-primary-button[disabled=disabled]:hover,
.shipengine-primary-button:disabled:active,
.shipengine-primary-button.disabled:active,
.shipengine-primary-button[disabled=disabled]:active {
  cursor: default;
  background: #502278;
  -webkit-box-shadow: 0 1px 1px rgba(76, 76, 76, 0.3);
          box-shadow: 0 1px 1px rgba(76, 76, 76, 0.3);
}
.button-danger {
  border: none;
  -o-border-image: none;
     border-image: none;
  padding: 0;
  position: relative;
  display: inline-block;
  width: auto;
  height: 30px;
  line-height: 28px;
  font-size: 13px;
  font-weight: 400;
  padding: 0 15px;
  border-radius: 3px;
  cursor: pointer;
  letter-spacing: 0.03em;
  background: #FF4016;
  color: #ffffff !important;
  border-top: 1px solid #FF4016;
  border-bottom: 1px solid #FF4016;
  -webkit-box-shadow: 0 1px 1px rgba(76, 76, 76, 0.3);
          box-shadow: 0 1px 1px rgba(76, 76, 76, 0.3);
  -webkit-transition: none !important;
  transition: none !important;
  text-decoration: none;
  text-shadow: 1px 1px 0 rgba(22, 4, 0, 0.3);
}
.button-danger:hover {
  background: #ff5d39;
  -webkit-box-shadow: 0 2px 3px rgba(76, 76, 76, 0.5);
          box-shadow: 0 2px 3px rgba(76, 76, 76, 0.5);
  border-bottom-color: #FF4016;
  border-top-color: #ff5d39;
  cursor: pointer;
  text-decoration: none !important;
}
.button-danger:focus {
  outline: 0;
}
.button-danger:focus:before {
  content: "";
  display: block;
  position: absolute;
  top: -3px;
  right: -2px;
  bottom: -3px;
  left: -2px;
  border-top: 3px solid rgba(201, 230, 255, 0.7);
  border-right: 2px solid rgba(201, 230, 255, 0.7);
  border-bottom: 3px solid rgba(201, 230, 255, 0.7);
  border-left: 2px solid rgba(201, 230, 255, 0.7);
  border-radius: 3px;
  z-index: 1;
}
.button-danger:active,
.button-danger.selected,
.button-danger.is-loading {
  background: #d93613;
  border-top-color: #b32d0f;
  border-top-width: 2px;
  border-bottom-color: #FF4016;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.button-danger:disabled,
.button-danger.disabled,
.button-danger[disabled=disabled] {
  opacity: 0.4;
}
.button-danger:disabled:hover,
.button-danger.disabled:hover,
.button-danger[disabled=disabled]:hover,
.button-danger:disabled:active,
.button-danger.disabled:active,
.button-danger[disabled=disabled]:active {
  cursor: default;
  background: #FF4016;
  -webkit-box-shadow: 0 1px 1px rgba(76, 76, 76, 0.3);
          box-shadow: 0 1px 1px rgba(76, 76, 76, 0.3);
}
.action {
  border: none;
  -o-border-image: none;
     border-image: none;
  padding: 0;
  position: relative;
  display: inline-block;
  width: auto;
  height: 30px;
  line-height: 28px;
  font-size: 13px;
  font-weight: 400;
  padding: 0 15px;
  border-radius: 3px;
  cursor: pointer;
  letter-spacing: 0.03em;
  background: #502278;
  color: #ffffff !important;
  border-top: 1px solid #502278;
  border-bottom: 1px solid #502278;
  -webkit-box-shadow: 0 1px 1px rgba(76, 76, 76, 0.3);
          box-shadow: 0 1px 1px rgba(76, 76, 76, 0.3);
  -webkit-transition: none !important;
  transition: none !important;
  text-decoration: none;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}
.action:hover {
  background: #623886;
  -webkit-box-shadow: 0 2px 3px rgba(76, 76, 76, 0.5);
          box-shadow: 0 2px 3px rgba(76, 76, 76, 0.5);
  border-bottom-color: #502278;
  border-top-color: #623886;
  cursor: pointer;
  text-decoration: none !important;
}
.action:focus {
  outline: 0;
}
.action:focus:before {
  content: "";
  display: block;
  position: absolute;
  top: -3px;
  right: -2px;
  bottom: -3px;
  left: -2px;
  border-top: 3px solid rgba(201, 230, 255, 0.7);
  border-right: 2px solid rgba(201, 230, 255, 0.7);
  border-bottom: 3px solid rgba(201, 230, 255, 0.7);
  border-left: 2px solid rgba(201, 230, 255, 0.7);
  border-radius: 3px;
  z-index: 1;
}
.action:active,
.action.selected,
.action.is-loading {
  background: #4c2072;
  border-top-color: #381854;
  border-top-width: 2px;
  border-bottom-color: #502278;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.action:disabled,
.action.disabled,
.action[disabled=disabled] {
  opacity: 0.4;
}
.action:disabled:hover,
.action.disabled:hover,
.action[disabled=disabled]:hover,
.action:disabled:active,
.action.disabled:active,
.action[disabled=disabled]:active {
  cursor: default;
  background: #502278;
  -webkit-box-shadow: 0 1px 1px rgba(76, 76, 76, 0.3);
          box-shadow: 0 1px 1px rgba(76, 76, 76, 0.3);
}
.cancel {
  border: none;
  -o-border-image: none;
     border-image: none;
  padding: 0;
  position: relative;
  display: inline-block;
  width: auto;
  height: 30px;
  line-height: 28px;
  font-size: 13px;
  font-weight: 400;
  padding: 0 15px;
  border-radius: 3px;
  cursor: pointer;
  letter-spacing: 0.03em;
  background: #EEEEEE;
  color: #4C4C4C !important;
  border-top: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
  -webkit-box-shadow: 0 1px 1px rgba(76, 76, 76, 0.3);
          box-shadow: 0 1px 1px rgba(76, 76, 76, 0.3);
  -webkit-transition: none !important;
  transition: none !important;
  text-decoration: none;
}
.cancel:hover {
  background: #f2f2f2;
  -webkit-box-shadow: 0 2px 3px rgba(76, 76, 76, 0.5);
          box-shadow: 0 2px 3px rgba(76, 76, 76, 0.5);
  border-bottom-color: #f0f0f0;
  border-top-color: #f2f2f2;
  cursor: pointer;
  text-decoration: none !important;
}
.cancel:focus {
  outline: 0;
}
.cancel:focus:before {
  content: "";
  display: block;
  position: absolute;
  top: -3px;
  right: -2px;
  bottom: -3px;
  left: -2px;
  border-top: 3px solid rgba(201, 230, 255, 0.7);
  border-right: 2px solid rgba(201, 230, 255, 0.7);
  border-bottom: 3px solid rgba(201, 230, 255, 0.7);
  border-left: 2px solid rgba(201, 230, 255, 0.7);
  border-radius: 3px;
  z-index: 1;
}
.cancel:active,
.cancel.selected,
.cancel.is-loading {
  background: #e9e9e9;
  border-top-color: #cacaca;
  border-top-width: 2px;
  border-bottom-color: #EEEEEE;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.cancel:disabled,
.cancel.disabled,
.cancel[disabled=disabled] {
  opacity: 0.4;
}
.cancel:disabled:hover,
.cancel.disabled:hover,
.cancel[disabled=disabled]:hover,
.cancel:disabled:active,
.cancel.disabled:active,
.cancel[disabled=disabled]:active {
  cursor: default;
  background: #EEEEEE;
  -webkit-box-shadow: 0 1px 1px rgba(76, 76, 76, 0.3);
          box-shadow: 0 1px 1px rgba(76, 76, 76, 0.3);
}
.action-link {
  cursor: pointer;
}
.error-section {
  border: 1px solid #FF4016;
  background-color: #FFE9E3;
  color: #FF4016;
  padding: 10px 0;
  text-align: center;
}
.error-section .error-message {
  padding: 5px 20px;
}
.input-error,
.input-error:focus {
  border: 1px solid #FF4016;
}
.common-page-header h1 {
  padding: 20px 30px 15px 0;
  margin-bottom: 0;
  font-size: 30px;
  line-height: 36px;
  font-weight: 400;
}
.common-page-header .subheader {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 15px;
}
.loader {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 12px;
  color: #4C4C4C;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 25px;
  margin: 10px;
  padding: 0 5px;
}
.loader .loading-indicator {
  height: 24px;
  width: 24px;
}
.inline-plus-icon {
  width: 9px;
  margin-right: 10px;
}
.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 15px;
}
.container {
  width: 100%;
  display: grid;
  grid-template-columns: 250px auto 100px 100px;
  grid-template-rows: auto;
  border: 1px solid #E0E0E0;
  border-bottom: none;
  font-size: 14px;
}
.container .header {
  height: 100%;
  min-height: 30px;
  font-weight: 600;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #F6F6F6), to(#EEEEEE));
  background: linear-gradient(180deg, #F6F6F6 0, #EEEEEE 100%);
  border-radius: 3px 3px 0 0;
  -ms-flex-item-align: center;
      align-self: center;
  padding: 5px 10px;
  border-bottom: 1px solid #E0E0E0;
}
.container .section {
  background-color: #ffffff;
}
.container .section button {
  background: none;
  border: none;
  height: 30px;
}
.container .section svg {
  fill: #FF4016;
}
.container .delete {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.container .input-section {
  padding-top: 7px;
  padding-bottom: 7px;
}
.container .input-section > input {
  min-width: 250px;
}
.container .text-section {
  padding-top: 12px;
  padding-bottom: 12px;
}
.container > * {
  padding-left: 10px;
  border-bottom: 1px solid #E0E0E0;
}
.footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 10px 0;
}
.footer :last-child {
  margin-left: 15px;
}
.error-section {
  margin: 0 100px 15px;
}
