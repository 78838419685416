.modal-manager {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
}
.modal-background {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
}
.modal-background.out {
  opacity: 0;
}
.modal-positioning-wrapper {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 10%;
  opacity: 0;
}
.modal-positioning-wrapper.in {
  margin-top: 0;
  opacity: 1;
}
.modal-component-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  padding: 30px;
  z-index: 2;
}
.modal-positioning-wrapper-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
@media only screen and (min-width: 1250px) and (max-width: 1439px) {
  .modal-component-wrapper {
    padding: 15px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1249px) {
  .modal-component-wrapper {
    padding: 15px;
  }
}
@media only screen and (min-width: 720px) and (max-width: 1024px) {
  .modal-component-wrapper {
    padding: 10px;
  }
}
