.currency-input-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.currency-input-container.disabled .currency-input {
  background: #EEEEEE;
}
.currency-input-symbol-wrapper {
  width: 16px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.currency-input-symbol {
  text-align: center;
  line-height: 30px;
}
.currency-input {
  width: calc(100% - 17px);
  text-align: right;
  display: block;
  border: 1px solid #909090;
  border-radius: 4px;
  background: #ffffff;
  -moz-appearance: textfield;
}
.currency-input:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.currency-input:invalid {
  border: 1px solid #FF4016;
}
.currency-input::-webkit-outer-spin-button,
.currency-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
