.number-input-with-spinners {
  position: relative;
  display: inline-block;
}
.number-input-with-spinners.invalid input,
.number-input-with-spinners.invalid button {
  border-color: #FF4016;
}
.number-input-with-spinners > input {
  display: block;
  width: 100%;
  padding: 5px 20px 5px 4px;
  text-align: right;
  height: 30px;
  background-color: #ffffff;
  border: 1px solid #909090;
  border-radius: 4px;
}
.number-input-with-spinners > input:focus {
  background: rgba(238, 248, 253, 0.5);
  border-color: #72BCE0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05), 0 0 6px rgba(139, 213, 249, 0.8);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05), 0 0 6px rgba(139, 213, 249, 0.8);
}
.number-input-with-spinners > input:disabled {
  background: #EEF8FD;
}
.number-input-with-spinners input::-webkit-inner-spin-button,
.number-input-with-spinners input::-webkit-outer-spin-button {
  -webkit-appearance: none;
          appearance: none;
}
.number-input-with-spinners button {
  display: block;
  width: 100%;
  height: 50%;
  border: 1px solid #909090;
  padding: 0;
  margin: 0;
  background: #E6F0F5;
  position: relative;
}
.number-input-with-spinners button:disabled {
  background: #EEF8FD;
}
.number-input-with-spinners button:disabled > i {
  color: #909090;
}
.number-input-with-spinners button:focus {
  outline: none;
  background: #BBC5CA;
  border-color: #4C4C4C;
}
.number-input-with-spinners button:first-child {
  border-top-right-radius: 4px;
  border-bottom: 0;
}
.number-input-with-spinners button:last-child {
  border-bottom-right-radius: 4px;
}
.number-input-with-spinners button > i {
  display: block;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 14px;
  color: #000000;
}
.buttons {
  width: 16px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
