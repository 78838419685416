.modal-close-button {
  height: 40px;
  width: 40px;
  min-height: 40px;
  min-width: 40px;
  cursor: pointer;
}
.modal-close-button svg {
  height: 11px;
  width: 11px;
  margin: 14.5px;
  fill: #C3C3C3;
}
.modal-close-button:hover svg {
  fill: #909090;
}
