.shipstation-connect-status {
  display: inline-block;
  border-right: 1px solid #C3C3C3;
  margin-left: 15px;
  margin-right: 15px;
  padding-right: 10px;
  height: 20px;
  vertical-align: middle;
}
.shipstation-connect-status span {
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
}
.shipstation-connect-status svg {
  display: inline-block;
  vertical-align: middle;
  height: 15px;
  width: 15px;
  margin-left: 5px;
}
.shipstation-connect-status.connected svg {
  fill: #6BA03A;
}
.shipstation-connect-status.disconnected svg {
  fill: #FF4016;
}
