.modal {
  width: 400px;
}
.header {
  text-align: center;
  padding-left: 30px;
}
.footer {
  height: auto !important;
}
.modal-content-wrapper {
  width: 100%;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.connection-store-name-input {
  width: 100%;
}
.connection-store-name-input input {
  color: #262626;
  font-weight: 400;
}
.connection-edit-save-button-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 15px 0;
  text-align: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -ms-flex-item-align: end;
      align-self: flex-end;
}
