.radio-check-button {
  display: block;
  position: relative;
  padding-top: 10px;
  padding-left: 25px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.radio-check-button.disabled {
  cursor: default;
}
.radio-check-button label {
  cursor: pointer;
}
.radio-check-button label.disabled {
  color: #BBC5CA;
  cursor: default;
}
.radio-check-button input {
  opacity: 0;
  position: absolute;
}
.radio-button {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #FFF;
  border: 1px solid #BBC5CA;
  border-radius: 50%;
}
.radio-button:hover {
  background-color: #EEF8FD;
}
.radio-button.checked:after {
  display: block;
}
.radio-button.disabled {
  background-color: #FFF;
  border: 1px solid #E6F0F5;
}
.radio-button:after {
  content: "";
  position: absolute;
  display: none;
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #8d60b5;
}
