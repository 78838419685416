.connnect-dropdowns {
  display: inline-block;
  max-width: 370px;
}
.connect-dropdown {
  margin-left: 10px;
  display: inline-block;
  max-width: 175px;
}
.connect-selected-value {
  font-size: 13px;
  max-width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
