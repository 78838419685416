.flex-input-container {
  border-radius: 4px;
  position: relative;
  height: 32px;
  padding: 0;
  margin: 5px;
  line-height: 28px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  background: #FFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
}
.flex-input-container.without-label {
  margin: 0;
}
.flex-input-container.error .flex-input {
  border: solid 1px #FF4016;
}
.flex-input-container.error .flex-label {
  color: #FF4016;
}
.flex-input-container:not(.disabled) > input:hover {
  border: solid 1px #6FB8F8;
}
.flex-input-container.disabled {
  opacity: 0.5;
}
.flex-input-container .flex-label {
  padding-right: 15px;
  white-space: nowrap;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: right;
}
.flex-input-container .flex-input-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 3;
      -ms-flex: 3;
          flex: 3;
}
.flex-input-container .flex-input {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 12px 10px;
  height: 100%;
  background: transparent;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  display: block;
}
.required-label {
  color: #FF4016;
  position: absolute;
  top: -5px;
  padding-left: 2px;
  font-size: 10px;
}
