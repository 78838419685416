:global .components-date-picker .CalendarDay,
:global .components-date-picker .CalendarDay--blocked-minimum-nights,
:global .components-date-picker .CalendarDay--blocked-out-of-range {
  border-color: #EEE;
}
:global .components-date-picker .CalendarDay {
  color: #000;
  border: 0;
  font-size: 14px;
  line-height: 14px;
}
:global .components-date-picker .CalendarDay--highlighted-calendar {
  color: #046BC0;
}
:global .components-date-picker .CalendarDay--highlighted-calendar:active {
  background: #046BC0;
}
:global .components-date-picker .CalendarDay--hovered {
  background: #C0EAFF;
}
:global .components-date-picker .CalendarDay--blocked-minimum-nights,
:global .components-date-picker .CalendarDay--blocked-out-of-range {
  color: #C3C3C3;
}
:global .components-date-picker .CalendarDay--selected-span {
  background: #C0EAFF;
  border-color: #C0EAFF;
}
:global .components-date-picker .CalendarDay--selected-span.CalendarDay--hovered,
:global .components-date-picker .CalendarDay--selected-span:active {
  background: #C0EAFF;
}
:global .components-date-picker .CalendarDay--selected-span.CalendarDay--last-in-range {
  border-right: #C0EAFF;
}
:global .components-date-picker .CalendarDay--hovered-span,
:global .components-date-picker .CalendarDay--after-hovered-start {
  background: #C0EAFF;
  color: #C0EAFF;
}
:global .components-date-picker .CalendarDay--hovered-span:active,
:global .components-date-picker .CalendarDay--after-hovered-start:active {
  background: #046BC0;
}
:global .components-date-picker .CalendarDay--selected-start,
:global .components-date-picker .CalendarDay--selected-end,
:global .components-date-picker .CalendarDay--selected {
  background: #046BC0;
}
:global .components-date-picker .CalendarDay--selected-start:active,
:global .components-date-picker .CalendarDay--selected-end:active,
:global .components-date-picker .CalendarDay--selected:active {
  background: #046BC0;
}
:global .components-date-picker .CalendarDay--blocked-calendar {
  background: #C3C3C3;
  color: #909090;
}
:global .components-date-picker .CalendarDay--blocked-calendar:active {
  background: #C3C3C3;
}
:global .components-date-picker .CalendarMonth_caption {
  color: #262626;
  font-size: 14px;
  line-height: 14px;
  padding-top: 17px;
  padding-bottom: 27px;
}
:global .components-date-picker .CalendarMonth_caption > * {
  font-weight: 400;
}
:global .components-date-picker .DayPicker_weekHeader {
  top: 40px;
}
:global .components-date-picker .DayPicker__week-header {
  color: #666;
}
:global .components-date-picker .DayPicker--vertical-scrollable .DayPicker__week-header {
  border-bottom: 1px solid #C3C3C3;
}
:global .components-date-picker .DayPickerNavigation__prev--default,
:global .components-date-picker .DayPickerNavigation__next--default {
  border-color: #E0E0E0;
  color: #666;
}
:global .components-date-picker .DayPickerNavigation__prev--default:focus,
:global .components-date-picker .DayPickerNavigation__prev--default:hover,
:global .components-date-picker .DayPickerNavigation__next--default:focus,
:global .components-date-picker .DayPickerNavigation__next--default:hover {
  border-color: #C3C3C3;
}
:global .components-date-picker .DayPickerNavigation__prev--default:active,
:global .components-date-picker .DayPickerNavigation__next--default:active {
  background: #F6F6F6;
}
:global .components-date-picker .DayPickerNavigation--horizontal .DayPickerNavigation__prev--default svg,
:global .components-date-picker .DayPickerNavigation--horizontal .DayPickerNavigation__next--default svg {
  fill: #262626;
}
:global .components-date-picker .DayPickerNavigation--vertical .DayPickerNavigation__prev--default svg,
:global .components-date-picker .DayPickerNavigation--vertical .DayPickerNavigation__next--default svg {
  fill: #262626;
}
:global .components-date-picker .KeyboardShortcutRow__key {
  background: #262626;
}
:global .components-date-picker .DateInput--disabled {
  background: #C3C3C3;
}
:global .components-date-picker .DateRangePicker__close svg {
  fill: #262626;
}
:global .components-date-picker .DateRangePicker__close:hover,
:global .components-date-picker .DateRangePicker__close:focus {
  color: #C3C3C3;
}
:global .components-date-picker .DateRangePickerInput {
  border-color: #C3C3C3;
}
:global .components-date-picker .DateRangePickerInput--disabled {
  background: #C3C3C3;
}
:global .components-date-picker .DateRangePickerInput__arrow svg {
  fill: #262626;
}
:global .components-date-picker .DateRangePickerInput__clear-dates svg {
  fill: #262626;
}
:global .components-date-picker .DateRangePickerInput__clear-dates:focus,
:global .components-date-picker .DateRangePickerInput__clear-dates--hover {
  background: #FFF;
}
:global .components-date-picker .PresetDateRangePicker_button__calendar-icon svg {
  fill: #262626;
}
:global .components-date-picker .SingleDatePicker__close svg {
  fill: #E0E0E0;
}
:global .components-date-picker .SingleDatePicker__close:hover,
:global .components-date-picker .SingleDatePicker__close:focus {
  color: #FFF;
}
:global .components-date-picker .SingleDatePickerInput__clear-date:focus,
:global .components-date-picker .SingleDatePickerInput__clear-date--hover {
  background: #FFF;
}
:global .components-date-picker .SingleDatePickerInput {
  border: 0;
  background: transparent;
  display: block;
}
:global .components-date-picker .SingleDatePicker__picker--direction-left {
  left: -100px !important;
}
:global .components-date-picker .SingleDatePickerInput__calendar-icon {
  margin: 0;
  padding: 0;
  height: 30px;
  vertical-align: top;
}
:global .components-date-picker .SingleDatePickerInput__calendar-icon svg {
  height: 30px;
  width: 30px;
  fill: #262626;
}
:global .components-date-picker .SingleDatePicker__picker {
  top: 100%;
  margin-top: 5px;
}
:global .components-date-picker .SingleDatePicker_picker {
  position: static !important;
}
:global .components-date-picker .DateInput,
:global .components-date-picker .DayPickerKeyboardShortcuts__show,
:global .components-date-picker .DateInput__input,
:global .components-date-picker .DateInput__display-text {
  display: none;
}
:global .components-date-picker .DayPickerNavigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 22px;
}
:global .components-date-picker .DayPickerNavigation > * {
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
}
:global .components-date-picker .DayPickerNavigation > *:focus {
  outline: none;
}
:global .components-date-picker .DayPickerNavigation > *:hover svg {
  background: #EEE;
  fill: #262626;
}
:global .components-date-picker .DayPickerNavigation > * svg {
  width: 30px;
  height: 30px;
  fill: #262626;
  display: inline-block;
  margin-bottom: -4px;
}
:global .components-date-picker .DayPicker__week-header li small {
  font-size: 14px;
  line-height: 14px;
}
:global .components-date-picker .DayPickerNavigation--horizontal .DayPickerNavigation__prev,
:global .components-date-picker .DayPickerNavigation--horizontal .DayPickerNavigation__next {
  border-radius: 0;
  padding: 0;
}
:global .components-date-picker .CalendarDay__button {
  border: 4px solid #FFF;
}
:global .components-date-picker .CalendarDay--selected {
  color: #FFF;
}
:global .components-date-picker .SingleDatePickerInput_calendarIcon {
  height: 30px;
  margin: 0;
  padding: 0;
}
:global .components-date-picker .CalendarDay__selected {
  color: #FFF;
  background: #8d60b5;
}
:global .components-date-picker .DayPickerNavigation_button {
  background-color: transparent;
  border: 0;
  height: 30px;
  margin-top: 10px;
}
