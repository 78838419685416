.stamps-setup-logos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.stamps-setup-logos > *:first-child {
  margin-right: 10px;
}
.existing-ups-account {
  padding-top: 10px;
}
