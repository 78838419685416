.wrapper {
  margin-top: 15px;
}
.api-description-input {
  border: 1px solid #C3C3C3;
}
.submit-button {
  float: right;
  margin-right: 6px;
}
.no-data-row {
  text-align: center;
  border-radius: 0 0 3px 3px;
  border-bottom: 1px solid #E0E0E0;
  background-color: #ffffff;
  grid-column: data / 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 30px;
}
