.scan-to-print-container-processing .scan-to-print-background {
  z-index: 180001;
}
.scan-to-print-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
}
.scan-to-print-toolbar-wrapper {
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  z-index: 18006;
}
.connect-wrapper {
  float: right;
}
