.credit-card-number {
  width: calc(60% - 10px);
}
.credit-card-expiration {
  width: calc(20% - 10px);
  overflow: hidden;
}
.credit-card-cvv {
  width: calc(20%);
}
