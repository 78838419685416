.modal-header {
  height: 52px;
  padding: 0 20px;
  border-bottom: 0;
  width: 100%;
  color: #ffffff;
  line-height: 52px;
  font-size: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.x-icon {
  height: 15px;
  width: 15px;
  fill: #ffffff;
  cursor: pointer;
}
