.error-content {
  border: 1px solid #FF4016;
  background: #FFE9E3;
  padding: 10px;
  border-bottom-right-radius: 6px;
}
.error-content-title {
  font-size: 16px;
}
.error-indicator-icon-wrapper {
  width: 15px;
  height: 30px;
  margin-right: 5px;
}
.error-indicator-icon-wrapper > svg {
  width: 100%;
  height: 100%;
  fill: #FF4016;
}
.error-indicator-wrapper {
  color: #FF4016;
}
.order-detail-link-wrapper {
  text-align: center;
  font-size: 16px;
  margin: 15px 0 5px;
}
.rate-content {
  text-align: center;
  padding: 10px;
  border-top: 1px solid #E0E0E0;
  line-height: 30px;
}
.rate-label {
  color: #909090;
  margin-right: 20px;
  display: inline-block;
  vertical-align: middle;
}
.rate-display {
  display: inline-block;
  vertical-align: middle;
}
.loading-indicator-wrapper {
  display: inline-block;
  vertical-align: middle;
  height: 30px;
  width: 24px;
}
.rate-text {
  color: #7DCA36;
  font-size: 30px;
  display: inline-block;
  vertical-align: middle;
}
.rate-text.zero-weight {
  color: #FF8000;
}
.purchase-and-print-button-wrapper {
  text-align: center;
  padding: 10px 10px 6px;
  border-top: 1px solid #E0E0E0;
}
.purchase-and-print-button span,
.purchase-and-print-button b {
  line-height: 30px;
  vertical-align: top;
}
.purchase-and-print-button b {
  font-size: 20px;
  margin-left: 10px;
  position: relative;
  top: -1px;
}
.edit-order-wrapper {
  margin-top: 3px;
}
