.shipstation-carrier-services-header {
  background: #F6F6F6;
  padding: 7px 14px;
}
.title-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  margin-bottom: 7px;
}
.title {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.balance-group {
  margin-left: 14px;
}
.balance-amount {
  color: #7DCA36;
  margin-left: 7px;
}
.add-funds-button {
  font-size: 13px;
  margin-left: 14px;
}
.settings-button {
  cursor: pointer;
  height: 16px;
  width: 16px;
  margin-left: 14px;
}
.settings-button svg {
  fill: #909090;
  height: 100%;
  width: 100%;
}
.settings-button:hover {
  opacity: 0.75;
}
.setup-description {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.setup-button {
  white-space: nowrap;
  margin-left: 65px;
}
