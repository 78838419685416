.label {
  font-size: 12px;
  line-height: 12px;
  color: #909090;
  margin-bottom: 3px;
}
.content {
  font-size: 14px;
  line-height: 16px;
}
.mdash {
  color: #909090;
}
