.scan-to-print-modal {
  display: table;
  min-height: 350px;
}
.left-column,
.right-column {
  display: table-cell;
  position: relative;
  min-height: 350px;
}
.left-column {
  width: 420px;
  z-index: 1;
  padding: 20px;
}
.right-column {
  width: 280px;
  background: #F6F6F6;
  z-index: 2;
  border-left: 1px solid #E0E0E0;
  -webkit-box-shadow: -1px 3px 0 rgba(0, 0, 0, 0.2);
          box-shadow: -1px 3px 0 rgba(0, 0, 0, 0.2);
}
.fulfillment-error-message {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: center;
  padding: 0 50px;
}
.fulfillment-error-edit-order-button {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
}
