.max-length-text-area.has-error .error {
  display: block;
}
.max-length-text-area.has-error textarea {
  border-color: #FF4016;
}
.error {
  color: #FF4016;
  display: none;
  margin-bottom: 5px;
}
