.error-message {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #4C4C4C;
  font-size: 16px;
  line-height: 1.5;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding: 10px;
}
.error-message.error {
  background: #FFE9E3;
}
.error-message.error .icon-and-label {
  border-right: 1px solid #FFAC9A;
}
.error-message.warning {
  background: #FFEAD5;
}
.error-message.warning .icon-and-label {
  border-right: 1px solid #FFB56A;
}
.icon-and-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-right: 10px;
  margin: 5px 15px 5px 0;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.state-icon {
  height: 30px;
  width: 30px;
  margin-right: 5px;
}
.children-wrapper {
  width: 100%;
  -ms-flex-item-align: center;
      align-self: center;
  font-size: 14px;
}
