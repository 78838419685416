.global-event-listener-root {
  -webkit-box-align: inherit;
      -ms-flex-align: inherit;
          align-items: inherit;
  display: inherit;
  height: inherit;
  -webkit-box-pack: inherit;
      -ms-flex-pack: inherit;
          justify-content: inherit;
  width: inherit;
}
.global-event-listener-root:focus {
  outline: none;
}
