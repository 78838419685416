.required-indicator-content {
  color: #FF4016;
  font-size: 13px;
  height: 18px;
  width: 18px;
  line-height: 18px;
  text-align: center;
  position: relative;
  font-weight: 700;
}
.required-indicator-content > span {
  position: relative;
  left: 3px;
  top: -2px;
}
.required-indicator-content:before {
  content: "";
  position: absolute;
  border: 9px solid rgba(255, 137, 111, 0.2);
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-top-right-radius: 4px;
  top: 0;
  left: 0;
}
