.open .dropdown-toggler-chevron-icon-wrapper > svg {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.dropdown-menu {
  display: inline-block;
}
.inner-dropdown-menu {
  border-radius: 4px;
  margin: 5px 0;
  border: 1px solid #BBC5CA;
  max-height: 300px;
  overflow: auto;
  max-width: 385px;
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
          box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}
