.plan-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: auto;
  height: 100%;
  padding: 10px 20px;
  border-radius: 6px;
  border: 3px solid #8d60b5;
  background-color: #f8f0ff;
}
.plan-label .wrapper h3 {
  color: #4380e8;
  text-transform: uppercase;
  text-align: left;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2px;
  margin: 0 0 2px;
  padding: 0;
}
.plan-label .wrapper div {
  font-size: 12px;
  color: #666666;
  text-transform: none;
  font-weight: 400;
  letter-spacing: normal;
  text-align: left;
}
.check {
  width: 25px;
  height: 25px;
  fill: #4380e8;
  margin-right: 10px;
}
