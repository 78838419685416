.fancy-state-icon {
  position: relative;
  height: 100%;
  width: 100%;
}
.fancy-state-icon:before {
  content: "";
  position: absolute;
  background: #FFF;
  z-index: 2;
}
.fancy-state-icon > svg {
  position: relative;
  z-index: 5;
}
.error-icon {
  background: #FFD6CC;
  border-radius: 50%;
}
.error-icon:before {
  top: 20%;
  right: 40%;
  bottom: 20%;
  left: 40%;
}
.error-icon > svg {
  fill: #FF4016;
  width: 148%;
  height: 148%;
  top: -24%;
  left: -24%;
}
.warning-icon {
  position: relative;
  height: 100%;
  width: 100%;
}
.warning-icon:before {
  top: 30%;
  right: 44%;
  bottom: 24%;
  left: 44%;
}
.warning-icon > svg {
  width: 120%;
  height: 120%;
  top: -10%;
  left: -10%;
  fill: #FF8000;
}
.info-icon {
  background: #C9E6FF;
  border-radius: 50%;
}
.info-icon:before {
  top: 20%;
  right: 40%;
  bottom: 20%;
  left: 40%;
}
.info-icon > svg {
  fill: #046BC0;
  width: 148%;
  height: 148%;
  top: -24%;
  left: -24%;
}
.triangle {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
  left: 0;
  right: 0;
  margin-top: -32%;
  margin-right: auto;
  margin-left: auto;
  background-color: #FFD9B2;
  width: 54%;
  height: 54%;
  -webkit-transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
          transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
  border-top-right-radius: 30%;
}
.triangle:before,
.triangle:after {
  content: "";
  position: absolute;
  background-color: inherit;
  width: 100%;
  height: 100%;
  border-top-right-radius: 30%;
}
.triangle:before {
  -webkit-transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
          transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
}
.triangle:after {
  -webkit-transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
          transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
}
.icon {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}
