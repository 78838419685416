.preview-modal {
  padding: 20px;
}
.preview-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 20px;
}
.preview-form-item-title {
  font-size: 16px;
  font-weight: bold;
  height: 22px;
  margin-bottom: 4px;
}
.preview-section {
  padding: 20px;
  border: 1px solid #C3C3C3;
  background: #EEEEEE;
}
.preview-title {
  font-size: 18px;
  margin-bottom: 20px;
  color: #6BA03A;
  text-align: center;
  font-weight: bold;
}
.zip-code-input {
  font-size: 14px;
  line-height: 38px;
  border-radius: 4px;
  border: 1px solid #C3C3C3;
  padding: 0 10px;
}
.loading-indicator-wrapper {
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.loading-indicator {
  height: 80px;
  width: 80px;
}
.rate {
  padding: 20px;
  border: 1px solid #909090;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #ffffff;
}
.rate:not(:first-child) {
  margin-top: -1px;
}
.radio-button-wrapper {
  margin-right: 10px;
}
.radio-button {
  border: 1px solid #E0E0E0;
  background: #ffffff;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  height: 20px;
  width: 20px;
  padding: 2px;
}
.radio-button.enabled {
  border: 1px solid #2C76B3;
}
.radio-button.enabled:before {
  content: "";
  height: calc(100% - 4px);
  width: calc(100% - 4px);
  background: #2C76B3;
  border-radius: 50%;
  overflow: hidden;
  top: 2px;
  left: 2px;
  position: absolute;
}
.rate-info {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.rate-name {
  font-weight: 700;
  font-size: 16px;
}
.rate-delivery-time {
  font-size: 14px;
}
.rate-description {
  font-size: 16px;
}
.rate-price {
  margin-left: 10px;
  font-weight: 700;
  font-size: 16px;
}
.description {
  margin-top: 20px;
  color: #909090;
  margin-bottom: 5px;
  font-size: 14px;
}
