.info-tooltip {
  height: 30px;
  width: 30px;
  padding: 5px;
}
.info-tooltip svg {
  height: 100%;
  width: 100%;
  fill: #2C76B3;
}
