.delivery-options-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 100%;
  border: 1px solid #E0E0E0;
  margin-top: -2px;
  background: #ffffff;
}
.delivery-options-row.title {
  background: #EEF8FD;
  border-top: 1px solid #E0E0E0;
}
.delivery-options-row.title > *:not(:last-child),
.delivery-options-row.title > *:not(:first-child) {
  border-right: 1px solid #E0E0E0;
}
.delivery-options-row.title .dragger {
  opacity: 0;
}
.delivery-options-row.title:hover .dragger {
  opacity: 0;
}
.delivery-options-row > * {
  padding: 5px;
}
.delivery-options-row > *:not(:last-child) {
  border-right: 1px solid transparent;
}
.delivery-options-row:hover {
  background: #E6F0F5;
}
.delivery-options-row:hover .dragger {
  opacity: 1;
}
.delivery-options-row.is-dragging {
  background: #E6F0F5;
}
.delivery-options-row.is-dragging .dragger {
  opacity: 1;
}
.drag-indicator-icon {
  fill: #909090;
}
.dragger {
  width: 30px;
  min-width: 30px;
  height: 30px;
  opacity: 0;
  padding: 5px;
}
.dragger > svg {
  height: 100%;
  width: 100%;
}
.name {
  width: 25%;
  min-width: 25%;
}
.services {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.service:not(:last-child) {
  margin-bottom: 5px;
}
.pricing {
  width: 15%;
}
.active-column {
  width: 50px;
  min-width: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  height: 30px;
}
.actions {
  width: 140px;
  min-width: 140px;
}
.actions > * {
  margin-right: 10px;
}
.active-check {
  fill: #7DCA36;
  height: 30px;
  width: 30px;
  padding: 5px;
  position: relative;
}
