.main-checkbox {
  padding: 3px 10px;
}
.color-label-error-state,
.color-error-text {
  color: #FF4016;
}
.color-error-text {
  display: block;
  margin: 10px auto;
}
