.row:after {
  content: "";
  display: table;
  clear: both;
}
.row:before,
.row:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.row:after {
  clear: both;
}
.row-item {
  float: left;
  margin-bottom: 10px;
  margin-right: 10px;
}
.row-item:after {
  content: "";
  display: table;
  clear: both;
}
.row-item:before,
.row-item:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.row-item:after {
  clear: both;
}
.row-item:last-child {
  margin-right: 0;
}
.first-name {
  width: calc(50% - 10px);
}
.last-name {
  width: calc(50%);
}
.email {
  width: calc(50% - 10px);
}
.phone {
  width: calc(50%);
}
.form-wrapper {
  width: 550px;
  margin-left: auto;
  margin-right: auto;
}
.form-wrapper h3 {
  font-size: 16px;
  font-weight: 700;
  padding: 10px 0;
  border-bottom: 1px solid #F6F6F6;
  margin-bottom: 12px;
}
.billing-error {
  list-style: none;
  color: #FF4016;
}
