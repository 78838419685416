.removable-service {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #ffffff;
  padding: 0 16px;
  font-size: 14px;
  line-height: 30px;
  border: 1px solid #E0E0E0;
}
.removable-service:not(:last-child) {
  margin-bottom: 5px;
}
.removable-service i {
  color: #4C4C4C;
}
.removable-service > *:first-child {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.removable-service > *:not(:last-child) {
  margin-right: 16px;
}
.x-button {
  height: 30px;
  width: 30px;
  cursor: pointer;
}
.x-button:hover svg {
  fill: #FF4016;
}
.icon {
  height: 30px;
  width: 30px;
  padding: 8px;
}
.shipping-account-selector :global .dropdown-option {
  background: transparent;
  border: 0;
  white-space: nowrap;
  padding: 5px;
  margin: 0;
}
