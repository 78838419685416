.bottom-section {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.print-label-button {
  text-align: center;
}
.print-label-button span,
.print-label-button b {
  line-height: 30px;
  vertical-align: top;
}
.print-label-button b {
  font-size: 20px;
  margin-left: 10px;
  position: relative;
  top: -1px;
}
.edit-order-button {
  text-align: center;
  padding: 5px 0;
}
.label-info > div {
  border-bottom: 1px solid #E0E0E0;
  font-size: 14px;
}
.label-created {
  padding: 0 10px;
  line-height: 40px;
}
.carrier-info {
  padding: 10px;
  background: #ffffff;
  line-height: 40px;
}
.carrier-logo-wrapper,
.service-name {
  float: left;
}
.carrier-logo-wrapper {
  height: 40px;
  width: 40px;
}
.carrier-logo-wrapper > img {
  height: 100%;
  width: 100%;
}
.service-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 10px;
  width: calc(100% - 40px);
  font-size: 16px;
  max-width: 219px;
}
.tracking-url {
  background: #C9E6FF;
  text-align: center;
  line-height: 30px;
}
