.edit-delivery-options-modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.edit-delivery-options-modal .percentage-input {
  width: 90px;
}
.edit-delivery-options-modal .percentage-input input {
  border: 1px solid #C3C3C3;
}
.body {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-height: calc(100% - 50px);
  overflow: auto;
  padding: 20px;
}
.footer {
  height: 50px;
  min-width: 50px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 16px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: 1px solid #C3C3C3;
}
.footer-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.footer-buttons > * {
  margin-left: 10px;
}
.edit-delivery-options-section {
  margin-bottom: 15px;
}
.edit-delivery-options-section.grey-section {
  padding: 16px;
  background: #EEEEEE;
}
.section-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}
.section-subtitle {
  font-size: 14px;
  color: #666666;
  margin-bottom: 8px;
}
.grey-section .section-title {
  margin-bottom: 10px;
}
.section-control-input {
  width: 100%;
  padding: 0 16px;
  font-size: 14px;
  line-height: 38px;
  border-radius: 4px;
  border: 1px solid #C3C3C3;
}
.section-control-dropdown-menu {
  font-size: 14px;
}
.section-control-dropdown-menu .section-control-dropdown-button {
  height: 40px;
  width: 200px;
}
.live-rate-section {
  position: relative;
  z-index: 1;
}
.live-rate-section:not(:last-child) {
  margin-bottom: 24px;
}
.live-rate-section.service-dropdown-section {
  z-index: 2;
}
.live-rate-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}
.pricing-controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.flat-rate-currency-input {
  width: 90px;
}
.fees-radio-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.fees-radio-buttons > * {
  margin-right: 16px;
}
.additional-fee-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.additional-fee-row > * {
  margin-right: 4px;
}
.additional-fee-row:not(:last-child) {
  margin-bottom: 12px;
}
.additional-fee-text {
  width: 170px;
}
.error-input {
  border: 1px solid #FF4016;
}
.shipping-service-picker-error select {
  border: 1px solid #FF4016;
}
.shipping-service-picker select {
  border: 1px solid #C3C3C3;
  height: 40px;
}
.error-message {
  font-size: 12px;
  color: #FF4016;
}
.services-error-message {
  margin-bottom: 10px;
}
.fallback-option-currency-input {
  height: 40px;
}
.currency-input {
  border: 1px solid #C3C3C3;
  width: 90px;
  height: 40px;
  padding-right: 17px;
}
.fallback-options-percentage-controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.fallback-options-percentage-controls > * {
  margin-right: 4px;
}
