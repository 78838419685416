.float-label-container {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  line-height: 28px;
  background: #FFF;
  border-radius: 4px;
}
.float-label-container.slide-label-up > label {
  top: 0;
  line-height: 20px;
  font-size: 10px;
}
.float-label-container.disabled {
  background: #F6F6F6;
}
.float-label-container.error > label {
  color: #FF4016;
}
.float-label-container > label {
  position: absolute;
  padding: 0 10px;
  line-height: 40px;
  color: #909090;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
