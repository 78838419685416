.subsection {
  font-size: 12px;
  padding-left: 10px;
}
.section-content {
  width: 100%;
  height: 100%;
}
.inputs-section {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.inputs-section label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.label {
  padding: 10px;
  font-size: 14px;
}
.label .label-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 15px;
  font-size: 18px;
  font-weight: 500;
}
.input {
  font-weight: 400;
  margin-left: 5px;
}
.action-section {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
