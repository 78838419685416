.dropdown-menu-toggler {
  text-align: left;
  max-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 30px;
  padding-right: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: auto;
  position: relative;
}
.dropdown-menu-toggler.childless {
  padding: 0;
}
.dropdown-menu-toggler.link .dropdown-toggler-content,
.dropdown-menu-toggler.danger-link .dropdown-toggler-content {
  line-height: 1.2;
}
.dropdown-menu-toggler.link .dropdown-toggler-chevron,
.dropdown-menu-toggler.danger-link .dropdown-toggler-chevron {
  margin-left: 5px;
  height: 16px;
  width: 16px;
  min-width: 16px;
}
.dropdown-menu-toggler.has-error {
  color: #FF4016 !important;
}
.dropdown-menu-toggler.has-error * {
  color: #FF4016 !important;
}
.dropdown-toggler-content {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.1;
  line-height: 1.2;
  width: 100%;
  max-width: 100%;
}
.dropdown-toggler-chevron {
  -ms-flex-item-align: center;
      align-self: center;
  position: relative;
  height: 28px;
  width: 28px;
}
.dropdown-toggler-chevron.open .dropdown-chevron-toggler-svg-wrapper > svg {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.dropdown-toggler-chevron svg {
  width: 8px;
  height: 6px;
  margin: 11px 10px;
}
.dropdown-chevron-toggler-svg-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.required-indicator {
  position: absolute;
  top: -1px;
  right: 0;
}
