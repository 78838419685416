.checkbox {
  font-size: 14px;
  cursor: pointer;
}
.checkbox input {
  display: none;
}
.checkbox label {
  margin-bottom: 0;
  cursor: pointer;
}
.checkbox.checked .check {
  display: inline-block;
}
.checkbox.checked .no-check {
  display: none;
}
.checkbox.disabled {
  color: #C3C3C3;
}
.checkbox.disabled label {
  cursor: default;
}
.label-text {
  vertical-align: top;
  display: inline-block;
  line-height: 18px;
}
.check,
.no-check {
  height: 18px;
  width: 18px;
  padding: 2px 0;
  display: inline-block;
  cursor: pointer;
}
.check {
  display: none;
  color: #4791CE;
}
.no-check {
  color: #909090;
}
