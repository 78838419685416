.container {
  border-bottom: 2px solid #F6F6F6;
  padding: 10px 15px 20px;
}
.message {
  padding-left: 20px;
  width: calc(60% - 10px);
  display: block;
  float: left;
}
.message p {
  font-size: 15px;
  color: #666666;
}
