.verification-step {
  padding: 0 20px;
}
.verification-step-heading {
  font-weight: 600;
  color: #4C4C4C;
  font-size: 24px;
}
.verification-step-description {
  font-weight: 400;
  font-size: 13px;
  color: #4C4C4C;
}
