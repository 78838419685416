.seller-verification-modal {
  margin: 0 auto;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.verification-modal-body {
  background-color: #EEEEEE;
  padding: 20px 30px;
  margin: -20px;
  height: 251px;
}
.verification-modal-footer {
  margin-top: 20px;
  padding: 10px;
  text-align: right;
}
.verification-secondary-button {
  margin-right: 5px;
}
