.billing-plan-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
  height: 380px;
}
.plan-summary-tax {
  margin-top: 10px;
  font-size: 13px;
  text-align: left;
}
.change-plan {
  margin-top: 15px;
  margin-left: 15px;
  float: left;
}
.billing-details {
  padding-top: 20px;
  padding-bottom: 40px;
}
.cancel-summary {
  padding-top: 20px;
  padding-bottom: 40px;
}
.update-billing-link {
  display: block;
  font-size: 16px;
  padding: 10px;
  margin-left: 10px;
}
.update-billing-link:hover {
  cursor: pointer;
}
.plan-billing-disclaimer {
  margin-bottom: 10px;
}
.credit-details-block {
  display: block;
  height: 100px;
}
