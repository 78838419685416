.modal {
  -webkit-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.5);
          box-shadow: 0 5px 25px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
.modal > :first-child {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  overflow: hidden;
}
.modal > :last-child {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow: hidden;
}
