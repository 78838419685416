.float-textarea {
  position: relative;
  height: 100%;
  padding: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  background: #FFF;
  border-radius: 4px;
  border: 1px solid #909090;
  min-height: 100px;
}
.float-textarea:focus-within {
  border-color: #8d60b5;
}
.float-textarea.error {
  border: solid 1px #FF4016;
}
.float-textarea.error > textarea {
  color: #FF4016;
}
.float-textarea.disabled > div {
  background-color: #F6F6F6;
}
.float-textarea > textarea {
  top: 18px;
  bottom: 0;
  left: 0;
  right: 0;
  border: 0;
  resize: none;
  position: absolute;
  z-index: 1;
  width: 100%;
  background: transparent;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  min-height: 60px;
  padding: 0 5px 0 10px;
  color: #4C4C4C;
  line-height: 20px;
  font-size: 14px;
}
.float-textarea > textarea:focus {
  outline: none;
}
