.modal.wizard .globalpost-modal {
  padding: 13px;
}
.modal.wizard .globalpost-modal .steps-logos {
  overflow: auto;
}
.modal.wizard .globalpost-modal .steps-logos img {
  width: 100%;
}
.modal.wizard .globalpost-modal img {
  margin-bottom: 20px;
}
.modal.wizard .globalpost-modal hr {
  margin-bottom: 13px;
}
.modal.wizard .globalpost-modal iframe {
  width: 100%;
  height: 390px;
}
.modal.wizard .globalpost-modal .globalpost-terms {
  display: none;
}
.modal.wizard .globalpost-modal .steps .step {
  height: 130px;
}
.modal.wizard .globalpost-modal .steps .step:last-child {
  height: 65px !important;
}
